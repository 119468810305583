import React, { useState, useRef, MouseEvent } from 'react';
import { useStylesReviewContainer } from '../../../hooks/index/useStyles';
import ReviewSliderItem from './ReviewSliderItem';
import Slider from 'react-slick';
import { LectureReview } from '../../review/interface';
import TitleBox from '../../common/styleComponent/TitleBox';
import { Container } from '@material-ui/core';
import './reviewSlider.css';
import useStatus from '../../../hooks/index/useStatus';
import { Skeleton } from '@material-ui/lab';

const settings = {
  dots: true,
  centerPadding: '25%',
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  centerMode: true,
  autoplay: true,
  autoplaySpeed: 4000,
  swipeToSlide: true,
  dotsClass: 'reivew_button__bar',
  arrows: false,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        centerPadding: '15%',
      },
    },
    {
      breakpoint: 1024,
      settings: {
        centerPadding: '10%',
      },
    },
    {
      breakpoint: 800,
      settings: {
        centerMode: false,
      },
    },
  ],
};

const ReviewSlider = () => {
  const classes = useStylesReviewContainer();
  const { lectureReviews } = useStatus();
  const [swiping, setSwiping] = useState(false);
  const carouselRef = useRef<any>();

  const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleMouseUp = () => {
    setSwiping(carouselRef.current!.innerSlider.state.swiping);
  };

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if (swiping) e.preventDefault();
  };

  return (
    <div className={classes.wrap}>
      <Container className={classes.titlecontainer}>
        <TitleBox text="수강후기" subtext="회원들의 솔직한 수강후기!" />
      </Container>
      <Slider {...settings} ref={carouselRef}>
        {lectureReviews.items.map((item: LectureReview) => (
          <div
            className={classes.slidediv}
            key={item.id}
            onClickCapture={handleClick}
            onMouseUpCapture={handleMouseUp}
            onMouseDownCapture={handleMouseDown}
          >
            <ReviewSliderItem item={item} />
          </div>
        ))}
        {lectureReviews.items.length === 0 && (
          <div className={classes.skeleton}>
            <Skeleton
              variant="rect"
              width="100%"
              height="100%"
              animation={false}
            />
          </div>
        )}
      </Slider>
    </div>
  );
};

export default ReviewSlider;
