import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ToggleButton } from '@material-ui/lab';

export const OrangeButton = withStyles({
  root: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#FF9E36',
    '&:hover': {
      backgroundColor: '#FF8936',
    },
  },
})(Button);

export const WhiteButton = withStyles({
  root: {
    fontWeight: 'bold',
    color: 'black',
    backgroundColor: '#FAFAFA',
    '&:hover': {
      backgroundColor: '#E3E3E3',
    },
  },
})(Button);

export const GreenButton = withStyles({
  root: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#68CA44',
    '&:hover': {
      backgroundColor: '#4BB922',
    },
  },
})(Button);

export const BlueButton = withStyles({
  root: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#4570D3',
    '&:hover': {
      backgroundColor: '#2556C7',
    },
  },
})(Button);

export const RedButton = withStyles({
  root: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#F72135',
    '&:hover': {
      backgroundColor: '#DF0015',
    },
  },
})(Button);

export const OrangeToggleButton = withStyles({
  root: {
    fontSize: '18px',
    color: '#000000',
    '&$selected': {
      backgroundColor: '#FF9E36',
      color: '#FFFFFF',
      fontWeight: 'bold',
      '&:hover': { backgroundColor: '#FF8936' },
    },
  },
  selected: {},
})(ToggleButton);
