import React from 'react';
import styled from 'styled-components';
import { Button, Grid, Typography } from '@material-ui/core';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import useLogout from '../../../../hooks/common/useLogout';

const RouterLink = styled(Link)`
  text-decoration: none;
`;

const TopNavbar = ({ history }: RouteComponentProps) => {
  const logout = useLogout(history);

  return (
    <>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs>
          <Typography variant="h5">
            {process.env.REACT_APP_INDEX_NAME === 'self' &&
              '독학동스쿨 관리자 모드'}
            {process.env.REACT_APP_INDEX_NAME === 'edu' &&
              '에듀동스쿨 관리자 모드'}
          </Typography>
        </Grid>
        <Grid item xs>
          <Grid container justify="flex-end">
            <Grid item>
              <Button
                color="primary"
                onClick={() => logout('admin')}
                style={{ color: 'white' }}
              >
                로그아웃
              </Button>
            </Grid>
            <Grid item>
              <RouterLink to="/">
                <Button color="primary" style={{ color: 'white' }}>
                  이용자 모드
                </Button>
              </RouterLink>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(TopNavbar);
