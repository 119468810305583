import React, { useState, useEffect } from 'react';
import { Paper, Grid, Divider, Typography } from '@material-ui/core';
import HeaderText from '../common/HeaderText';
import { useStyleList } from '../../../hooks/admin/CPMailManage/useStyle';
import { CPMail } from './interface';
import { useQuery } from '@apollo/react-hooks';
import { CP_MAIL_LIST_QUERY } from './gql';
import CPMailListItem from './CPMailListItem';

const cpMailManageList = () => {
  const classes = useStyleList();
  const [cpMailList, setCpMailList] = useState<CPMail[]>([]);

  const { data, refetch } = useQuery(CP_MAIL_LIST_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (item) => {
      return item;
    },
  });

  useEffect(() => {
    if (data) {
      setCpMailList([...data.contentProviderMail]);
    }
  }, [data]);

  return (
    <>
      <Grid item xs={12}>
        <HeaderText header="CP사 메일 관리" />
      </Grid>
      <Grid className={classes.divider} item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper} elevation={4}>
          <Grid container spacing={1} className={classes.titleBar}>
            <Grid item xs={5}>
              <Typography className={classes.title}>CP 이름</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography className={classes.title}>CP 메일</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.title}>수정</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider></Divider>
            </Grid>
          </Grid>
          {cpMailList.map((cpMail) => (
            <CPMailListItem cpMail={cpMail} refetch={refetch} key={cpMail.id} />
          ))}
        </Paper>
      </Grid>
    </>
  );
};

export default cpMailManageList;
