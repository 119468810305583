import { createAction } from 'typesafe-actions';
import { Cart, Book } from '../../components/mypage/interface';

//장바구니 목록 가져오기
export const GET_CART = 'cart/GET_CART';
export const getCart = createAction(GET_CART)<Cart[]>();

//체크리스트에 추가
export const ADD_CHECK_ITEM = 'cart/ADD_CHECK_ITEM';
export const addCheckItem = createAction(ADD_CHECK_ITEM)<number>(); //카트아이템 아이디

//체크리스트에서 제거
export const REMOVE_CHECK_ITEM = 'cart/REMOVE_CHECK_ITEM';
export const removeCheckItem = createAction(REMOVE_CHECK_ITEM)<number>(); //카트아이템 아이디

//장바구니의 상품에서 책 추가
export const ADD_ITEM_BOOK = 'cart/ADD_ITEM_BOOK';
export const addItemBook = createAction(ADD_ITEM_BOOK)<{
  id: number;
  book: Book;
}>(); //카트아이템 아이디, 책

//장바구니의 상품에서 책 제거
export const REMOVE_ITEM_BOOK = 'cart/REMOVE_ITEM_BOOK';
export const removeItemBook = createAction(REMOVE_ITEM_BOOK)<{
  id: number;
  book: Book;
}>(); //카트아이템 아이디, 책

//장바구니에서 체크리스트 삭제
export const REMOVE_CHECKLIST = 'cart/REMOVE_CHECKLIST';
export const removeCheckList = createAction(REMOVE_CHECKLIST)();
