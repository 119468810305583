import React, { useState } from 'react';
import {
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import SettingsApplications from '@material-ui/icons/SettingsApplications';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Link } from 'react-router-dom';
import { useStylesNavbar } from '../../../../hooks/admin/navbar/useStyle';

export const SettingNavbar = () => {
  const classes = useStylesNavbar();

  const [open, setOpen] = useState(false);

  const handleContentClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem button onClick={handleContentClick}>
        <ListItemIcon>
          <SettingsApplications />
        </ListItemIcon>
        <ListItemText primary="환경설정" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to="/admin/setting/image" className={classes.linkText}>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="이미지 관리" />
            </ListItem>
          </Link>
          <Link to="/admin/setting/smsForm" className={classes.linkText}>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="문자 양식 관리" />
            </ListItem>
          </Link>
          <Link to="/admin/setting" className={classes.linkText}>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="사이트 정보 관리" />
            </ListItem>
          </Link>
        </List>
      </Collapse>

      <Divider />
    </>
  );
};

export default SettingNavbar;
