//루트 리듀서
import { combineReducers } from 'redux';
import auth from './auth';
import cart from './cart';
import main from './main';
import order from './order';
import adminBoard from './admin/board';
import adminLecture from './admin/lecture';
import adminCourse from './admin/course';
import adminProduct from './admin/product';
import permission from './admin/permission';
import adminTemporary from './admin/temporary';
import adminSetting from './admin/setting';

const rootReducer = combineReducers({
  auth,
  cart,
  main,
  order,
  permission,
  adminCourse,
  adminProduct,
  adminBoard,
  adminLecture,
  adminTemporary,
  adminSetting,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
