import React from 'react';
import User from './UserSideNavbar';
import Point from './PointSideNavbar';
import Product from './ProductSideNavbar';
import Review from './ReviewSideNavbar';
import Order from './OrderSideNavbar';
import Board from './BoardSideNavbar';
import Setting from './SettingSideNavbar';
import Period from './PeriodSideNavbar';
import Analysis from './AnalysisSideNavbar';

import { AdminPageRestrictComponent } from '../../common/AdminRestrictComponent';

const RootNavbar = () => {
  return (
    <>
      {/* 부관리자가 볼 수 있는 메뉴 */}
      <AdminPageRestrictComponent path="/admin/user" component={<User />} />
      <AdminPageRestrictComponent path="/admin/order" component={<Order />} />
      <AdminPageRestrictComponent path="/admin/period" component={<Period />} />
      {/* 부관리자가 볼 수 없는 메뉴 */}
      <AdminPageRestrictComponent
        path="/admin/product"
        component={<Product />}
      />
      <AdminPageRestrictComponent path="/admin/point" component={<Point />} />
      <AdminPageRestrictComponent path="/admin/review" component={<Review />} />
      <AdminPageRestrictComponent path="/admin/post" component={<Board />} />
      <AdminPageRestrictComponent
        path="/admin/setting"
        component={<Setting />}
      />
      <AdminPageRestrictComponent
        path="/admin/analysis"
        component={<Analysis />}
      />
    </>
  );
};

export default RootNavbar;
