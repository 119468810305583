import React, { useEffect } from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  IconButton,
  Grid,
  Typography,
  Divider,
} from '@material-ui/core';
import { useStylesTerm } from '../../../hooks/auth/useStyles';
import CloseIcon from '@material-ui/icons/Close';
import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const GET_USETERMS = gql`
  query getSiteConfig {
    SiteConfig {
      useTerm
    }
  }
`;

export const GET_PRIVACYPOLICY = gql`
  query getSiteConfig {
    SiteConfig {
      privacyPolicy
    }
  }
`;

interface TermModalProps {
  open: boolean;
  handleOpen: () => void;
  type: 1 | 2;
}

const TermModal = ({ open, handleOpen, type }: TermModalProps) => {
  const classes = useStylesTerm();

  const [getSiteConfig, { data }] = useLazyQuery(
    type === 1 ? GET_USETERMS : GET_PRIVACYPOLICY,
    {
      onError: () => console.log('불러오기 실패'),
    },
  );

  useEffect(() => {
    if (open) {
      getSiteConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, type]);

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      className={classes.modal}
      open={open}
      onClose={handleOpen}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Grid container className={classes.modalpaper} spacing={2}>
          <IconButton className={classes.closebtn} onClick={handleOpen}>
            <CloseIcon className={classes.closeicon} />
          </IconButton>
          <Grid item xs={12}>
            <Typography className={classes.title}>
              {type === 1 ? '이용약관' : '개인정보 처리방침'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {data && (
            <Grid item xs={12} className={classes.content}>
              <Typography className={classes.text}>
                {type === 1
                  ? data.SiteConfig.useTerm
                  : data.SiteConfig.privacyPolicy}{' '}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Fade>
    </Modal>
  );
};

export default TermModal;
