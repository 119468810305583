import React from 'react';
import { Grid } from '@material-ui/core';
import BoardList from './BoardList';
import { Board } from '../../help/interface';
import { useStylesTitle } from '../../../hooks/index/useStyles';
import TitleBox from '../../common/styleComponent/TitleBox';
import { Link } from 'react-router-dom';
import { WhiteButton } from '../../common/customStyle/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import useStatus from '../../../hooks/index/useStatus';

const BoardContainer = () => {
  const { board } = useStatus();
  const classes = useStylesTitle();

  return (
    <Grid container spacing={3}>
      {board.map((item: Board, i: number) => {
        if (i > 2) return null;
        return (
          <Grid item xs={12} sm={6} key={item.id}>
            <div className={classes.wrap}>
              <TitleBox text={item.name} />
              <BoardList boardId={item.id} />
              <Link to={`/help/board?id=${item.id}`} className={classes.link}>
                <WhiteButton className={classes.morebtn}>
                  더보기 <ArrowForwardIosIcon className={classes.moreicon} />
                </WhiteButton>
              </Link>
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default BoardContainer;
