import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(17, 5, 2),
      [theme.breakpoints.up('sm')]: {
        margin: theme.spacing(20, 10, 2),
      },
      [theme.breakpoints.up('md')]: {
        margin: theme.spacing(33, 11, 0),
      },
    },
    titlecontainer: {
      marginBottom: theme.spacing(3),
      width: '100%',
      maxWidth: 440,
      margin: 'auto',
    },
    title: {
      fontWeight: 'bold',
      fontSize: 29,
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        fontSize: 21,
      },
    },
    subtitle: {
      fontSize: 20,
      fontWeight: 'lighter',
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        fontSize: 15,
      },
    },
    form: {
      width: '100%',
      maxWidth: 440,
      margin: 'auto',
    },
    submit: {
      height: '100%',
      fontSize: 20,
      width: '100%',
    },
    link: {
      color: '#2B2B2B',
      textDecoration: 'none',
    },
    linktext: {
      fontSize: 15,
    },
    input: {
      marginTop: theme.spacing(2),
    },
    idicon: {
      marginLeft: 3,
      marginRight: 3,
      color: '#FF8936',
    },
    pwicon: {
      color: '#FF8936',
    },
    naverlogindiv: {
      textAlign: 'center',
    },
    naverloginbtn: {
      marginTop: theme.spacing(3),
      width: '100%',
      height: 53,
      background: '#1EC800',
      color: 'white',
      '&:hover': {
        background: '#1EC800',
      },
      maxWidth: 440,
    },
    loginbtnicon: {
      marginLeft: 5,
    },
    naverloginbtntext: {
      fontWeight: 'bold',
      fontSize: 18,
      [theme.breakpoints.down('xs')]: {
        fontSize: 17,
      },
      flexGrow: 1,
    },
  }),
);

export default useStyles;

export const useStylesRegister = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      maxWidth: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: 0,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
      },
    },
    title: {
      fontWeight: 'bold',
      fontSize: 30,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(2),
    },
    inputContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    inputContainerTitle: {
      fontWeight: 'bold',
      fontSize: 20,
      color: '#5A5A5A',
    },
    divider: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
      background: '#E6E6E6',
      opacity: 0.5,
    },
    label: {
      fontSize: 22,
      fontWeight: 'bold',
      [theme.breakpoints.up('xs')]: {
        fontSize: 18,
      },
    },
    birthText: {
      fontSize: 18,
    },
    phonebutton: {
      marginTop: theme.spacing(1),
    },
    moment: {
      color: 'red',
      marginLeft: theme.spacing(1),
      fontSize: 14,
    },
    postbtn: {
      height: '100%',
      width: '100%',
      paddingLeft: 20,
      paddingRight: 20,
      fontSize: 18,
      [theme.breakpoints.up('xs')]: {
        fontSize: 16,
      },
    },
    arrowbtn: {
      height: 30,
      marginLeft: theme.spacing(1),
    },
    termdiv: {
      border: '0.5px solid #E3DBD8',
    },
    termline: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    termbutton: {
      width: 70,
      height: 25,
      marginTop: theme.spacing(1),
      paddingTop: 4,
      fontSize: 15,
    },
    termtext: {
      padding: theme.spacing(1),
      opacity: 0.5,
      fontWeight: 'bold',
      fontSize: 13,
      textAlign: 'center',
    },
    registerbtn: {
      fontSize: 25,
      height: 65,
    },
    phoneicon: {
      textAlign: 'center',
      width: '100%',
    },
  }),
);

export const useStylesTerm = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalpaper: {
      backgroundColor: theme.palette.background.paper,
      border: '0px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 3, 3),
      width: '90%',
      maxWidth: 1200,
      height: '80%',
      maxHeight: 650,
      position: 'relative',
      overflow: 'hidden',
      outline: 0,
    },
    closebtn: {
      position: 'absolute',
      top: 8,
      right: 12,
    },
    closeicon: {
      fontSize: 30,
    },
    title: {
      fontSize: 20,
      fontWeight: 'bold',
      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
      },
    },
    content: {
      height: '100%',
      maxHeight: 560,
    },
    text: {
      height: '98%',
      overflowY: 'scroll',
      opacity: 0.8,
      whiteSpace: 'pre-wrap',
      fontSize: 12,
    },
  }),
);

export const useStylesTemplate = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      minHeight: 900,
    },
    loginform: {
      widdth: '100%',
      [theme.breakpoints.up('md')]: {
        maxWidth: 600,
      },
    },
    imgwrap: {
      position: 'relative',
    },
    imgbox: {
      position: 'absolute',
      top: 190,
      left: '50%',
      width: '100%',
      maxWidth: 640,
      transform: 'translateX(-50%)',
      zIndex: 1,
      textAlign: 'center',
    },
    img: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
      width: '100%',
    },
    text: {
      fontSize: 25,
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    divider: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: 25,
      border: '2px solid #717171',
      marginBottom: theme.spacing(3),
    },
    subtext: {
      fontSize: 15,
      fontWeight: 'lighter',
    },
    topdiv: {
      height: '56%',
      transform: 'matrix(-1, 0, 0, -1, 0, 0)',
      background:
        'transparent linear-gradient(0deg, #FFFFFF 0%, #EFEFEF 100%) 0% 0% no-repeat padding-box',
    },
    bottomdiv: {
      height: '44%',
      transform: 'matrix(-1, 0, 0, -1, 0, 0)',
      background:
        'transparent linear-gradient(0deg, #FFFFFF 0%, #FFFFFF 46%, #EFEFEF 100%) 0% 0% no-repeat padding-box',
    },
  }),
);

export const useStylesOutput = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(12),
      maxWidth: 700,
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'center',
    },
    box: {
      padding: theme.spacing(2),
      textAlign: 'center',
      width: '100%',
    },
    light: {
      fontSize: 21,
      fontWeight: 'lighter',
      marginBottom: theme.spacing(1),
    },
    bold: {
      fontSize: 29,
      fontWeight: 'bold',
      marginBottom: theme.spacing(1),
    },
    link: {
      textDecoration: 'none',
    },
    linkbtn: {
      width: 120,
      fontSize: 20,
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(2),
        width: '100%',
      },
    },
    orderlinkbtn: {
      width: 400,
      height: 67,
      fontSize: 20,
      marginBottom: theme.spacing(7),
      [theme.breakpoints.down('xs')]: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%',
        marginBottom: 0,
      },
    },
    imgbox: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto',
      maxWidth: 320,
    },
  }),
);

export const useStylesIDPWSearch = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: 700,
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: theme.spacing(1),
    },
    title: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      fontWeight: 'bolder',
      fontSize: 20,
    },
    btngroup: {
      width: '100%',
    },
    reqbtn: {
      fontSize: 18,
    },
    groupbtn: {
      width: '100%',
      height: 30,
      fontSize: 16,
      paddingTop: 0,
      borderRadius: 3,
      fontWeight: 'bolder',
    },
    content: {
      marginTop: theme.spacing(2),
    },
    heading: {
      fontSize: 15,
    },
    label: {
      fontSize: 16,
      fontWeight: 'bold',
    },
    arrowicon: {
      paddingTop: 2,
      [theme.breakpoints.down('xs')]: {
        paddingTop: 1,
        fontSize: 18,
      },
    },
    expire: {
      color: 'red',
    },
  }),
);

export const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
  },
})(MuiExpansionPanel);
