import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 270;

export const useStylesNavbar = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      minWidth: '900px',
      position: 'relative',
    },
    header: {},
    side: {
      //   color: 'white',
      //   backgroundColor: 'grey',
    },
    appBar: {
      backgroundColor: '#424242',
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    toolbar: theme.mixins.toolbar,
    appBarShift: {
      backgroundColor: '#424242',
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      backgroundColor: 'white',
      width: drawerWidth,
    },
    drawerHeader: {
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
      marginTop: '64px',
      width: '100%',
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    linkText: {
      textDecoration: 'none',
      color: 'black',
    },
    logo: {
      width: '100px',
    },
  }),
);
