import React from 'react';
import { Grid } from '@material-ui/core';
import CategoryBestList from './CategoryBestList';
import img1 from '../../../img/illustration/category1.svg';
import img2 from '../../../img/illustration/category2.svg';
import img3 from '../../../img/illustration/category3.svg';
import TitleBox from '../../common/styleComponent/TitleBox';

const CategoryBestContainer = () => {
  return (
    <>
      <TitleBox
        text="카테고리별 인기 강의"
        subtext="분야별 인기 강의를 만나보세요!"
      />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <CategoryBestList imgsrc={img1} i={0} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CategoryBestList imgsrc={img2} i={1} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CategoryBestList imgsrc={img3} i={2} />
        </Grid>
      </Grid>
    </>
  );
};

export default CategoryBestContainer;
