import { createReducer } from 'typesafe-actions';
import { BoardState } from './interface'; //상태ts타입 가져오기
import { BoardAction } from './types'; //액션ts타입 가져오기
import { SELECT_BOARD, SET_INQUIRY_COUNT } from './actions'; //액션가져오기

const initialState: BoardState = {
  id: 0,
  name: '',
  count: {},
};

// 리듀서 만들기
const board = createReducer<BoardState, BoardAction>(initialState, {
  [SELECT_BOARD]: (state, { payload: { id, name } }) => {
    return {
      ...state,
      id,
      name,
    };
  },
  [SET_INQUIRY_COUNT]: (state, { payload: { count } }) => {
    return {
      ...state,
      count: {
        ...state.count,
        ...count,
      },
    };
  },
});

export default board;
