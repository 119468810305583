import gql from 'graphql-tag';

//메인에쓰이는 데이터들
// 추천강의,
// 인기강의,
// 게시판카테고리,
// 수강후기
export const GET_INDEX_DATA = gql`
  query indexData {
    recommendProducts: products(
      pagination: { skip: 0, take: 6 }
      where: { isRecommended: { value: true }, hide: { value: false } }
    ) {
      items {
        id
        name
        price
        period
        summary
        thumbnail {
          url
        }
      }
    }
    bestProducts: products(
      pagination: { skip: 0, take: 5 }
      where: { hide: { value: false } }
      sort: { orderCount: DESC }
    ) {
      items {
        id
        name
        price
        summary
        thumbnail {
          url
        }
      }
    }

    board {
      id
      name
    }
    lectureReviews(
      pagination: { skip: 0, take: 5 }
      where: { isBad: { value: false } }
    ) {
      items {
        id
        lecture {
          name
        }
        learningCourse {
          course {
            name
          }
        }
        method
        content
        teacher
        book
        video
        whatHaveILearned
        thoughtsOnTeacher
        overallThoughts
        user {
          accountId
        }
        createdAt
      }
    }
    blogReviews(where: { hide: { value: false } }) {
      id
      image {
        url
      }
      url
      title
    }
  }
`;

//메인에 쓰이는 이미지들
// 메인슬라이드
// 중간배너
// 하단배너 pc버전, 모바일버전
export const GET_INDEX_IMAGES = gql`
  query indexPageImages {
    indexPageImages {
      mainSlides {
        image {
          url
        }
        url
      }
      mainAds {
        image {
          url
        }
        url
      }
      siteBannerPC {
        image {
          url
        }
        url
      }
      siteBannerMobile {
        image {
          url
        }
        url
      }
    }
  }
`;

// 강의리스트
export const GET_MANY_PRODUCT = gql`
  query getManyProducts($categoryId: [Int!]) {
    products(
      pagination: { skip: 0, take: 4 }
      where: { categoryId: { value: $categoryId }, hide: { value: false } }
      sort: { priority: DESC }
    ) {
      items {
        id
        name
        price
        period
        thumbnail {
          url
        }
      }
    }
  }
`;

export const GET_CATEGORY_ARTICLES = gql`
  query articles($skip: Int, $take: Int, $boardId: [Int!]) {
    articles(
      pagination: { skip: $skip, take: $take }
      where: { boardId: { value: $boardId } }
    ) {
      items {
        id
        title
        updateAt
        boardId
        createdAt
      }
      total
      hasMore
    }
  }
`;

// //과정카테고리, 게시판 카테고리,
// export const GET_CATEGORY_PRODUCT = gql`
//   query ProductCategory {
//     ProductCategory {
//       id
//       name
//     }
//     board {
//       id
//       name
//     }
//   }
// `;

//추천 강의
// export const GET_RECOMMEND_PRODUCT = gql`
//   query products($skip: Int, $take: Int) {
//     products(pagination: { skip: $skip, take: $take }) {
//       items {
//         id
//         name
//         price
//         summary
//         thumbnail {
//           url
//         }
//       }
//     }
//   }
// `;
