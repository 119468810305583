import { createAction } from 'typesafe-actions';

import { BoardState } from './interface';

export const SELECT_BOARD = 'board/SELECT_BOARD';
export const selectBoard = createAction(SELECT_BOARD)<{
  id: number;
  name: string;
}>();

export const SET_INQUIRY_COUNT = 'board/SET_INQUIRY_COUNT';
export const setInquiryCount = createAction(SET_INQUIRY_COUNT)<{
  count: BoardState['count'];
}>();
