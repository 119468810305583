import { createAction } from 'typesafe-actions';

import { CourseState } from './interface';

export const CONNECT_TEACHER = 'course/CONNECT_TEACHER';
export const connectTeacher = createAction(CONNECT_TEACHER)<{
  teachers: CourseState['teachers'];
}>();

export const DISCONNECT_TEACHER = 'course/DISCONNECT_TEACHER';
export const disconnectTeacher = createAction(DISCONNECT_TEACHER)<{
  id: number;
}>();

export const CLAER_TEACHER = 'course/CLEAR_TEACHER';
export const clearTeacher = createAction(CLAER_TEACHER)();

export const CONNECT_BOOK = 'course/CONNECT_BOOK';
export const connectBook = createAction(CONNECT_BOOK)<{
  books: CourseState['books'];
}>();

export const DISCONNECT_BOOK = 'course/DISCONNECT_BOOK';
export const disconnectBook = createAction(DISCONNECT_BOOK)<{
  id: number;
}>();

export const CLAER_BOOK = 'course/CLEAR_BOOK';
export const clearBook = createAction(CLAER_BOOK)();
