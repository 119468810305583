import React, { FormEvent, ChangeEvent, useState } from 'react';
import { CPMail } from './interface';
import { useStyleListItem } from '../../../hooks/admin/CPMailManage/useStyle';
import { Grid, TextField } from '@material-ui/core';
import { BlueButton } from '../common/Button';
import { useUpdateCPMail } from '../../../hooks/admin/CPMailManage/useUpdate';

interface CPMailListItemProps {
  cpMail: CPMail;
  refetch: () => void;
}

const CPMailListItem = ({ cpMail, refetch }: CPMailListItemProps) => {
  const classes = useStyleListItem();
  const updateCPMail = useUpdateCPMail(refetch);

  const [inputData, setInputData] = useState({
    cpName: cpMail.name,
    email: cpMail.user?.email,
  });

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setInputData({
      ...inputData,
      [name]: value,
    });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    updateCPMail({
      variables: {
        id: cpMail.id,
        userId: cpMail.user?.id,
        ...inputData,
      },
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        container
        spacing={1}
        className={classes.container}
        alignItems="center"
      >
        <Grid item xs={5}>
          <TextField
            fullWidth
            name="cpName"
            variant="outlined"
            className={classes.textField}
            value={inputData.cpName}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            fullWidth
            name="email"
            variant="outlined"
            className={classes.textField}
            value={inputData.email}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={2}>
          <BlueButton className={classes.button} type="submit">
            수정
          </BlueButton>
        </Grid>
        {/* <Grid item xs={2} className={classes.btnBox}>
          <RedButton className={classes.button}>삭제</RedButton>
        </Grid> */}
      </Grid>
    </form>
  );
};

export default CPMailListItem;
