import React, { useState } from 'react';
//style
import { useStylesPCBottom } from '../../../hooks/header/useStyles';
import { Popover } from '@material-ui/core';
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined';
//component
import FamilySiteList from '../../common/familySite/FamilySiteList';

const PCBottomMenu = () => {
  const classes = useStylesPCBottom();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <div
        className={classes.div}
        id="familysite"
        aria-describedby={id}
        onClick={handleClick}
      >
        <WidgetsOutlinedIcon fontSize="small" className={classes.icon} />
        <span className={classes.text}>패밀리사이트</span>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <FamilySiteList />
      </Popover>
    </>
  );
};

export default PCBottomMenu;
