import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(9),
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: 30,
      },
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(5),
      },
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
  }),
);

export default useStyles;

//푸터
export const useStylesFooter = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      width: '100%',
      marginTop: 'auto',
      [theme.breakpoints.down('xs')]: {
        paddingBottom: 67,
      },
      borderTop: '1px solid #E6E6E6',
    },
    gridcontainer: {
      [theme.breakpoints.up('lg')]: {
        width: 1280,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(2),
      },
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(3),
      },
      padding: theme.spacing(2),
    },
    textbox: {
      fontSize: 15,
      opacity: 0.7,
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
      fontWeight: 'lighter',
      marginTop: theme.spacing(1),
    },
    buttonbox: {
      borderTop: '1px solid #E6E6E6',
      borderBottom: '1px solid #E6E6E6',
    },
    link: {
      textDecoration: 'none',
    },
    logo: {
      width: 150,
    },
    textwrap: {
      [theme.breakpoints.up('lg')]: {
        width: 1240,
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: theme.spacing(2, 1, 2),
      },
      padding: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
        justifyContent: 'space-between',
      },
    },
    text: {
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: {
        fontWeight: 'lighter',
      },
      fontSize: 18,
      [theme.breakpoints.up('sm')]: {
        marginRight: theme.spacing(3),
      },
    },
    callbox: {
      fontSize: 22,
      textAlign: 'right',
    },
    callcenter: {
      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
      },
    },
    callNumber: {
      fontSize: 32,
      fontWeight: 'bold',
      [theme.breakpoints.down('xs')]: {
        fontSize: 40,
      },
    },
    mobilebox: {
      paddingBottom: theme.spacing(2),
      marginBottom: theme.spacing(3),
      borderBottom: '1px solid #E6E6E6',
    },
    time: {
      fontWeight: 'lighter',
      [theme.breakpoints.down('xs')]: {
        fontSize: 10,
      },
    },
    snsiconbox: {
      [theme.breakpoints.down('xs')]: {
        textAlign: 'left',
      },
      textAlign: 'right',
      marginTop: theme.spacing(1),
    },
    snsicon: {
      marginRight: 5,
      cursor: 'pointer',
    },
  }),
);

//사이트맵
export const useStylesSiteMap = makeStyles((theme: Theme) =>
  createStyles({
    mobiledivider: {
      width: '95%',
      margin: 'auto',
    },
    categoryname: {
      fontSize: 15,
      fontWeight: 'bold',
      color: '#2B2B2B',
      paddingLeft: theme.spacing(1),
    },
    divider: {
      height: 15,
      width: 3,
      marginTop: 3,
      background: '#FF9E36',
      float: 'left',
      borderRadius: 2,
    },
    arrowicon: {
      color: '#2B2B2B',
    },
    listitem: {
      marginTop: theme.spacing(1),
    },
    collapse: {
      marginBottom: theme.spacing(1),
    },
    childname: {
      paddingLeft: theme.spacing(2),
      fontSize: 15,
      color: '#2B2B2B',
    },
    sitemap: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.up('lg')]: {
        width: 1240,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
      },
    },
    sitemapbox: {
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 5,
        paddingRight: 5,
      },
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingBottom: theme.spacing(2),
      borderRight: '1px solid #E6E6E6',
      textAlign: 'left',
    },
    mypagebox: {
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 5,
        paddingRight: 5,
      },
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingBottom: theme.spacing(2),
      textAlign: 'left',
    },
    link: {
      textDecoration: 'none',
    },
    bigtext: {
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
        marginBottom: 3,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
      fontSize: 25,
      fontWeight: 'bold',
      cursor: 'pointer',
      marginBottom: 5,
    },
    smalltext: {
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 10,
      },
      fontSize: 20,
      fontWeight: 'lighter',
      cursor: 'pointer',
      marginBottom: theme.spacing(1),
    },
    sitemapdivider: {
      border: '2px solid #FF8936',
      [theme.breakpoints.down('sm')]: {
        width: 10,
        border: '1px solid #FF8936',
      },
      width: 20,
      marginBottom: theme.spacing(2),
      textAlign: 'left',
    },
    leftSide: {
      width: '100%',
      height: 92,
      marginBottom: theme.spacing(2),
      position: 'relative',
    },
    overlay: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: 'rgba(0,0,0,.7)',
    },
    lefticonbox: {
      top: '35%',
      position: 'relative',
      textAlign: 'center',
      maxWidth: 120,
      margin: 'auto',
    },
    lefticon: {
      color: '#ffffff',
      fontSize: 120,
    },
    sitemapicon: {
      float: 'left',
      marginTop: 5,
      marginRight: 7,
    },
    lefttext: {
      color: '#ffffff',
      fontSize: 20,
    },
  }),
);

export const useStylesModal = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalpaper: {
      backgroundColor: theme.palette.background.paper,
      border: '0px',
      boxShadow: theme.shadows[5],
      width: '100%',
      maxWidth: 540,
      position: 'relative',
      outline: 0,
    },
    modalpaperRecipe: {
      paddingTop: theme.spacing(4),
      backgroundColor: theme.palette.background.paper,
      border: '0px',
      boxShadow: theme.shadows[5],
      width: '90%',
      height: 'auto',
      maxHeight: '90%',
      maxWidth: 540,
      position: 'relative',
      overflowY: 'scroll',
      outline: 0,
    },
    modalpaperCenter: {
      backgroundColor: theme.palette.background.paper,
      border: '0px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(5, 3, 5),
      textAlign: 'center',
      width: 300,
      height: 300,
      position: 'relative',
      outline: 0,
    },
    modalclosebtn: {
      position: 'absolute',
      top: 5,
      right: 5,
    },
    modalcloseicon: {
      fontSize: 30,
    },
    img: {
      width: '100%',
      height: 'auto',
      maxWidth: 140,
    },
    restimg: {
      width: '100%',
      height: 'auto',
    },
    box: {
      background: '#878A8C',
      padding: theme.spacing(3, 2, 3),
    },
    text: {
      fontSize: 22,
      fontWeight: 'bold',
      marginTop: theme.spacing(1),
    },
    title: {
      fontSize: 22,
      fontWeight: 'lighter',
      color: 'white',
    },
    closebtn: {
      fontSize: 30,
      cursor: 'pointer',
      color: '#ffffff',
    },
    downloadbtn: {
      width: '100%',
      height: 60,
      fontSize: 22,
      fontWeight: 'bold',
      borderRadius: 0,
    },
    btngroup: {
      width: '100%',
      position: 'absolute',
      left: 0,
      bottom: 0,
      background: 'linear-gradient(100deg, #FF9E36 0%, #FF7536 100%)',
    },
    btn: {
      width: '100%',
      height: 60,
      fontSize: 20,
      color: '#ffffff',
      paddingTop: 12,
    },
    btncursor: {
      cursor: 'pointer',
    },
    divider: {
      marginTop: 18,
      height: 20,
      border: '1px solid #FFFFFF',
      opacity: 0.5,
    },
  }),
);

export const useStylesTitle = makeStyles((theme: Theme) =>
  createStyles({
    titlebox: {
      color: '#2B2B2B',
    },
    divider: {
      [theme.breakpoints.down('sm')]: {
        height: 19,
      },
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      background: '#FF9E36',
      border: '3px solid #FF9E36',
      height: 27,
      float: 'left',
      borderRadius: 2,
    },
    smalldivider: {
      [theme.breakpoints.down('sm')]: {
        height: 19,
        marginTop: 5,
      },
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      background: '#FF9E36',
      border: '2px solid #FF9E36',
      height: 23,
      float: 'left',
      borderRadius: 2,
    },
    title: {
      fontSize: 30,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: 25,
      },
    },
    smalltitle: {
      fontSize: 25,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
      },
    },
    subtitle: {
      fontSize: 15,
      fontWeight: 'lighter',
      marginLeft: 10,
    },
  }),
);

export const useStylesFamilySite = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(5),
      },
      [theme.breakpoints.up('md')]: {
        width: 1100,
        height: 830,
      },
    },
    leftSide: {
      width: 200,
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: 92,
        marginBottom: theme.spacing(2),
      },
      position: 'relative',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    overlay: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: 'rgba(0,0,0,.7)',
    },
    lefticonbox: {
      top: '30%',
      position: 'relative',
      textAlign: 'center',
    },
    lefticon: {
      color: '#ffffff',
      fontSize: 120,
    },
    lefttext: {
      color: '#ffffff',
      fontSize: 25,
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
      },
    },
    titlebox: {
      marginTop: theme.spacing(1),
      color: '#2B2B2B',
    },
    sitecontainer: {
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3, 4, 4),
      },
    },
    linkbox: {
      border: '1px solid #E6E6E6',
      padding: theme.spacing(1),
      '&:hover': {
        cursor: 'pointer',
        opacity: 1,
        backgroundColor: '#F2F2F2',
        transition: 'background-color 0.2s',
      },
    },
    sitename: {
      [theme.breakpoints.up('md')]: {
        fontSize: 20,
      },
      fontSize: 15,
    },
    subtext: {
      [theme.breakpoints.up('md')]: {
        fontSize: 15,
      },
      fontSize: 13,
      fontWeight: 'lighter',
    },
    arrowicon: {
      fontSize: 15,
      color: '#707070',
      marginTop: theme.spacing(2),
    },
    title: {
      fontSize: 20,
      fontWeight: 'bolder',
    },
    titledivider: {
      marginTop: 5,
      marginRight: theme.spacing(1),
      background: '#FF9E36',
      border: '2px solid #FF9E36',
      height: 20,
      float: 'left',
      borderRadius: 2,
    },
  }),
);

export const useStylesCircle = makeStyles((theme: Theme) =>
  createStyles({
    circleContainer: {
      paddingLeft: 3,
      paddingBottom: 10,
      [theme.breakpoints.down('xs')]: {
        paddingTop: theme.spacing(1),
      },
    },
    circle: {
      width: 5,
      height: 5,
      borderRadius: '50%',
      background: '#FF8936',
      float: 'left',
      marginRight: 5,
    },
  }),
);

export const useStylesMenuCard = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      marginTop: theme.spacing(2),
    },
    card: {
      padding: theme.spacing(3, 2, 3),
      border: '1px solid #E6E6E6',
      textAlign: 'center',
      height: '100%',
      transition: 'background .3s',
      '&:hover': {
        cursor: 'pointer',
        background: '#FAFAFA',
      },
    },
    iconimg: {
      width: 'auto',
      height: '100%',
      maxHeight: 73,
      padding: theme.spacing(1),
    },
    link: {
      textDecoration: 'none',
    },
    linkTitle: {
      fontSize: 25,
      fontWeight: 'bolder',
      [theme.breakpoints.down('sm')]: {
        fontSize: 22,
      },
    },
    linkbody: {
      whiteSpace: 'pre-wrap',
      fontWeight: 'lighter',
      fontSize: 15,
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
    },
  }),
);

export const useStylesEmpty = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: 'none',
    },
    iconbox: {
      textAlign: 'center',
    },
    icon: {
      width: '100%',
      maxWidth: 200,
      height: 'auto',
      [theme.breakpoints.down('xs')]: {
        maxWidth: 150,
      },
    },
    emptytext: {
      marginTop: theme.spacing(2),
      fontWeight: 'bold',
      opacity: 0.5,
      fontSize: 22,
      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
      },
    },
    courseLink: {
      marginTop: theme.spacing(2),
      paddingLeft: 20,
      paddingRight: 20,
      fontSize: 20,
      fontWeight: 'bold',
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(1),
        fontSize: 16,
      },
    },
  }),
);

//네비게이션
export const useStylesTopTitle = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: 'none',
    },
    prevlink: {
      opacity: 0.5,
      fontSize: 13,
      color: '#2B2B2B',
      [theme.breakpoints.down('xs')]: {
        fontSize: 10,
      },
    },
    nowlink: {
      fontSize: 13,
      color: '#2B2B2B',
      [theme.breakpoints.down('xs')]: {
        fontSize: 10,
      },
    },
  }),
);

export const useStylesNotFound = makeStyles((theme: Theme) =>
  createStyles({
    imgbox: {
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(3),
      },
      marginTop: theme.spacing(10),
      textAlign: 'center',
    },
    img: {
      width: '100%',
      maxWidth: 700,
    },
    title: {
      fontSize: 30,
      fontWeight: 'bold',
      color: '#C1C1C1',
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        fontSize: 25,
      },
      marginBottom: theme.spacing(1),
    },
    subtitle: {
      fontSize: 15,
      fontWeight: 'bold',
      color: '#C1C1C1',
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        fontSize: 13,
      },
    },
    link: {
      width: '100%',
      maxWidth: 200,
      textDecoration: 'none',
    },
    linkbtn: {
      height: 50,
      fontSize: 15,
    },
  }),
);
