//메인페이지 데이터  업데이트
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { getMainData, MainDataState } from '../../modules/main';

export default function useGetMainData() {
  const dispatch = useDispatch();

  return useCallback(
    (mainData: MainDataState) => dispatch(getMainData(mainData)),
    [dispatch],
  );
}
