import React, { useState } from 'react';
import {
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Link } from 'react-router-dom';
import { useStylesNavbar } from '../../../../hooks/admin/navbar/useStyle';

const ReviewNavbar = () => {
  const classes = useStylesNavbar();

  const [open, setOpen] = useState(false);

  const onReviewClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem button onClick={onReviewClick}>
        <ListItemIcon>
          <ChatOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="후기 관리" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to={`/admin/review/lecture`} className={classes.linkText}>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="일반 후기" />
            </ListItem>
          </Link>
          <Link to={`/admin/review/course`} className={classes.linkText}>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="프리미엄 후기" />
            </ListItem>
          </Link>
        </List>
        <List component="div" disablePadding>
          <Link to={`/admin/review/bad`} className={classes.linkText}>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="악성 후기 관리" />
            </ListItem>
          </Link>
        </List>
        <List component="div" disablePadding>
          <Link to={`/admin/review/keyword`} className={classes.linkText}>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="악성 키워드 관리" />
            </ListItem>
          </Link>
        </List>
        <List component="div" disablePadding>
          <Link to={`/admin/review/blog`} className={classes.linkText}>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="블로그 리뷰 관리" />
            </ListItem>
          </Link>
        </List>
      </Collapse>

      <Divider />
    </>
  );
};

export default ReviewNavbar;
