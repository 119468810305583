import React, { useState, useRef, MouseEvent } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import './mainSlider.css';
import { useStylesSlide } from '../../../hooks/index/useStyles';
import { Slide } from '../interface';
import { Skeleton } from '@material-ui/lab';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  dotsClass: 'button__bar',
  arrows: false,
  slidesToScroll: 1,
};

interface MainSliderProps {
  slideList?: Slide[];
}

const MainSlider = ({ slideList = [] }: MainSliderProps) => {
  const classes = useStylesSlide();
  const [swiping, setSwiping] = useState(false);
  const carouselRef = useRef<any>();

  const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleMouseUp = () => {
    setSwiping(carouselRef.current!.innerSlider.state.swiping);
  };

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if (swiping) e.preventDefault();
  };

  return (
    <Slider {...settings} ref={carouselRef}>
      {slideList.map((slide: Slide, i: number) => (
        <div
          key={i}
          onClickCapture={handleClick}
          onMouseUpCapture={handleMouseUp}
          onMouseDownCapture={handleMouseDown}
          className={classes.slide}
        >
          {slide.url.includes('http') ? (
            <Link to={{ pathname: slide.url }} target="_blank">
              <Paper
                className={classes.titleImg}
                style={{ backgroundImage: `url(${slide.image.url})` }}
                square
                elevation={0}
              />
            </Link>
          ) : (
            <Link to={slide.url}>
              <Paper
                className={classes.titleImg}
                style={{ backgroundImage: `url(${slide.image.url})` }}
                square
                elevation={0}
              />
            </Link>
          )}
        </div>
      ))}
      {slideList.length === 0 && (
        <div className={classes.slide}>
          <Skeleton
            variant="rect"
            width="100%"
            height="100%"
            animation="wave"
          />
        </div>
      )}
    </Slider>
  );
};

export default MainSlider;
