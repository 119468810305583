//관리자 로그인 상태 업데이트
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { adminAccess, PermissionState } from '../../modules/auth';

export default function useAdminAccess() {
  const dispatch = useDispatch();

  return useCallback(
    ({
      accountId,
      isAdmin,
      name,
      apiPermissions,
      pagePermissions,
      useSpecificPermission,
    }: PermissionState) =>
      dispatch(
        adminAccess({
          accountId,
          isAdmin,
          name,
          apiPermissions,
          pagePermissions,
          useSpecificPermission,
        }),
      ),
    [dispatch],
  );
}
