import { createReducer } from 'typesafe-actions';
import { CourseState } from './interface';
import { CourseAction } from './types';
import {
  CONNECT_TEACHER,
  DISCONNECT_TEACHER,
  CLAER_TEACHER,
  CONNECT_BOOK,
  DISCONNECT_BOOK,
  CLAER_BOOK,
} from './actions';

const initioalState: CourseState = {
  teachers: [],
  books: [],
};

const course = createReducer<CourseState, CourseAction>(initioalState, {
  // teacher control
  [CONNECT_TEACHER]: (state, { payload: { teachers } }) => {
    return {
      ...state,
      teachers: [...state.teachers, ...teachers],
    };
  },
  [DISCONNECT_TEACHER]: (state, { payload: { id } }) => {
    return {
      ...state,
      teachers: [...state.teachers.filter((item) => item.id !== id)],
    };
  },
  [CLAER_TEACHER]: (state) => {
    return {
      ...state,
      teachers: [],
    };
  },
  // book control
  [CONNECT_BOOK]: (state, { payload: { books } }) => {
    return {
      ...state,
      books: [...state.books, ...books],
    };
  },
  [DISCONNECT_BOOK]: (state, { payload: { id } }) => {
    return {
      ...state,
      books: [...state.books.filter((item) => item.id !== id)],
    };
  },
  [CLAER_BOOK]: (state) => {
    return {
      ...state,
      books: [],
    };
  },
});

export default course;
