import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyleList = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      marginTop: '20px',
      marginBottom: '20px',
    },
    paper: {
      padding: theme.spacing(1),
      width: '100%',
      minWidth: 960,
      marginBottom: theme.spacing(1),
    },
    title: {
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 'bold',
    },
    titleBar: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
);

export const useStyleListItem = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: theme.spacing(1),
      textAlign: 'center',
    },
    textField: {
      marginTop: '10px',
    },
    button: {
      marginTop: '10px',
      width: '70%',
    },
  }),
);
