import React, { useState } from 'react';
import {
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Link } from 'react-router-dom';
import { useStylesNavbar } from '../../../../hooks/admin/navbar/useStyle';
import { OnlyRootAdminComponent } from '../../common/AdminRestrictComponent';

const UserNavbar = () => {
  const classes = useStylesNavbar();

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <AccountBoxIcon />
        </ListItemIcon>
        <ListItemText primary="회원 관리" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to="/admin/user" className={classes.linkText}>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="회원 관리" />
            </ListItem>
          </Link>
          <OnlyRootAdminComponent
            component={
              <>
                <Link to="/admin/manager" className={classes.linkText}>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ArrowRightIcon />
                    </ListItemIcon>
                    <ListItemText primary="관리자 관리" />
                  </ListItem>
                </Link>
                <Link to="/admin/role" className={classes.linkText}>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ArrowRightIcon />
                    </ListItemIcon>
                    <ListItemText primary="등급 관리" />
                  </ListItem>
                </Link>
                <Link to="/admin/memo" className={classes.linkText}>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ArrowRightIcon />
                    </ListItemIcon>
                    <ListItemText primary="메모 관리" />
                  </ListItem>
                </Link>
                <Link to="/admin/cplist" className={classes.linkText}>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ArrowRightIcon />
                    </ListItemIcon>
                    <ListItemText primary="CP 관리" />
                  </ListItem>
                </Link>
              </>
            }
          />
        </List>
      </Collapse>
      <Divider />
    </>
  );
};

export default UserNavbar;
