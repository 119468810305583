import { createAction } from 'typesafe-actions';
import { PayMentMethod } from '../../util/PaymentTranslation';
import {
  DeliveryForm,
  UserInfo,
  AddressInfo,
  Phone,
  OrderPrice,
  VoucherCard,
} from './interface';

//결제가격, 포인트
export const UPDATE_PRICE = 'order/UPDATE_PRICE';
export const updatePrice = createAction(UPDATE_PRICE)<OrderPrice>();

//초기 정보 가져오기
export const GET_ALL_INFO = 'order/GET_ALL_INFO';
export const getAllInfo = createAction(GET_ALL_INFO)<DeliveryForm>();

//유저정보변동
export const UPDATE_USER_INFO = 'order/UPDATE_USER_INFO';
export const updateUserInfo = createAction(UPDATE_USER_INFO)<UserInfo>();

//핸드폰번호 변동
export const UPDATE_PHONE = 'order/UPDATE_PHONE';
export const updatePhone = createAction(UPDATE_PHONE)<Phone>();

//주소변동
export const UPDATE_ADDRESS_INFO = 'order/UPDATE_ADDRESS_INFO';
export const updateAddressInfo = createAction(
  UPDATE_ADDRESS_INFO,
)<AddressInfo>();

//기본배송지선택
export const ADDRESS_CHANGE_CHECK = 'order/ADDRESS_CHANGE_CHECK';
export const changeAddressCheck = createAction(ADDRESS_CHANGE_CHECK)<boolean>();

//결제수단선택
export const UDATE_PAYMENT_METHOD = 'order/UDATE_PAYMENT_METHOD';
export const updatePaymentMethod = createAction(
  UDATE_PAYMENT_METHOD,
)<PayMentMethod>();

//바우처카드정보입력
export const UPDATE_VOUCHER_CARD = 'order/UPDATE_VOUCHER_CARD';
export const updateVoucherCard = createAction(
  UPDATE_VOUCHER_CARD,
)<VoucherCard>();
