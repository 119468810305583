import gql from 'graphql-tag';

export const CP_MAIL_LIST_QUERY = gql`
  query contentProviderMail {
    contentProviderMail {
      id
      name
      user {
        id
        email
      }
    }
  }
`;
