import React, { useEffect, useState } from 'react';
import { Typography, Grid } from '@material-ui/core';
import ProductCard from './ProductCard';
import { Product } from '../interface';
import { useStylesList } from '../../../hooks/index/useStyles';
import { GET_MANY_PRODUCT } from '../gql';
import { useLazyQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import useStatus from '../../../hooks/index/useStatus';
interface CategoryBestListProps {
  imgsrc: string;
  i: number;
}

const CategoryBestList = ({ imgsrc, i }: CategoryBestListProps) => {
  const classes = useStylesList();
  const { category } = useStatus();
  const [product, setProduct] = useState<Product[]>([]);

  const [getProduct] = useLazyQuery(GET_MANY_PRODUCT, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setProduct(data.products.items);
    },
    // eslint-disable-next-line react/display-name
    onError: () => {
      return <>서버 요청 에러</>;
    },
  });

  useEffect(() => {
    if (category.length > i) {
      getProduct({
        variables: {
          categoryId: category[i].id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  const CourseSkeleton = (
    <Grid item xs={6} sm={12} md={6}>
      <div className={classes.skeletoncard}>
        <Skeleton
          variant="rect"
          width="100%"
          height="100%"
          className={classes.skeletonimg}
          animation={false}
        />
      </div>
    </Grid>
  );

  return (
    <div className={classes.wrap}>
      <div className={classes.categorywrap}>
        <Link
          to={`/course/list/${category.length > i ? category[i].id : ''}`}
          className={classes.categorylink}
        >
          <Grid container className={classes.categorycontainer}>
            <Grid item xs className={classes.titlecontiner}>
              <Typography className={classes.title} color="textPrimary">
                {category.length > i ? category[i].name : ''}
              </Typography>
              <Typography className={classes.linktext} color="textSecondary">
                바로가기 {'>'}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              lg={6}
              className={classes.imgbox}
              style={{ backgroundImage: `url(${imgsrc})` }}
            />
          </Grid>
        </Link>
      </div>
      <Grid container>
        {product.length === 0 ? (
          <>
            {CourseSkeleton}
            {CourseSkeleton}
            {CourseSkeleton}
            {CourseSkeleton}
          </>
        ) : (
          product.map((item: Product) => (
            <Grid item xs={6} sm={12} md={6} key={item.id}>
              <ProductCard item={item} />
            </Grid>
          ))
        )}
      </Grid>
    </div>
  );
};

export default CategoryBestList;
