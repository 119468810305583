import { Card, CardContent, CardMedia, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useStylesBlogReviewItem } from '../../../hooks/index/useStyles';
import { BlogReview } from '../interface';
import doubleQuoteFront from '../../../img/icon/doubleQuoteFront.svg';
import doubleQuoteBack from '../../../img/icon/doubleQuoteBack.svg';

interface BlogReviewSliderItemProps {
  blogReview: BlogReview;
}

const BlogReviewSliderItem = ({ blogReview }: BlogReviewSliderItemProps) => {
  const classes = useStylesBlogReviewItem();

  const [isHover, setIsHover] = useState(false);

  const onHover = () => {
    setIsHover(true);
  };

  const onHoverOut = () => {
    setIsHover(false);
  };

  return (
    <Card
      className={classes.item}
      elevation={isHover ? 8 : 2}
      onMouseOver={onHover}
      onMouseOut={onHoverOut}
      onClick={() => window.open(blogReview.url)}
    >
      <CardMedia image={blogReview.image.url} className={classes.image} />
      <CardContent className={classes.titleWrap}>
        <Typography className={classes.title} component="h2">
          <img src={doubleQuoteFront} className={classes.frontQuote} />
          {blogReview.title}
          <img src={doubleQuoteBack} className={classes.backQuote} />
        </Typography>
      </CardContent>
    </Card>
  );
};

export default BlogReviewSliderItem;
