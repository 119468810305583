import { createAction } from 'typesafe-actions';

import { LectureState, ILecture } from './interface';

export const SET_LECTURES = 'lecture/SET_LECTURES';
export const setLectures = createAction(SET_LECTURES)<ILecture[]>();

export const SET_MAX_ORDER = 'lecture/SET_MAX_ORDER';
export const setMaxOrder = createAction(SET_MAX_ORDER)<number>();

export const SELECT_PREVIEW = 'lecture/SELECT_PREVIEW';
export const selectPreview = createAction(SELECT_PREVIEW)<{
  preview: number;
}>();

export const SET_VIMEO_PROGRESS = 'lecture/SET_VIMEO_PROGRESS';
export const setVimeoProgress = createAction(SET_VIMEO_PROGRESS)<
  LectureState['progress']
>();

export const ADD_LECTURES = 'lecture/ADD_LECTURES';
export const addLectures = createAction(ADD_LECTURES)<ILecture[]>();

export const UPDATE_LECTURE = 'lecture/UPDATE_LECTURE';
export const updateLecture = createAction(UPDATE_LECTURE)<ILecture>();

export const DELETE_LECTURES = 'lecture/DELETE_LECTURES';
export const deleteLectures = createAction(DELETE_LECTURES)<{
  ids: number[];
}>();

export const SET_UPDATE_LECTURE = 'lecture/SET_UPDATE_LECTURE';
export const setUpdateLecture = createAction(SET_UPDATE_LECTURE)<ILecture>();

export const CLEAR_SELECTED_LECTURES = 'lecture/CLEAR_SELECTED_LECTURES';
export const clearSelectedLectures = createAction(CLEAR_SELECTED_LECTURES)();

export const UPDATE_SELECTED_LECTURES = 'lecture/UPDATE_SELECTED_LECTURES';
export const updateSelectedLectures = createAction(UPDATE_SELECTED_LECTURES)<{
  newSelectedLectures: ILecture[];
  newLastSelectedIndex: number;
}>();

export const UPDATE_LECTURE_INSERT_INDEX =
  'lecture/UPDATE_LECTURE_INSERT_INDEX';
export const updateLectureInsertIndex = createAction(
  UPDATE_LECTURE_INSERT_INDEX,
)<{
  newHoverIndex: number;
  newInsertIndex: number;
}>();
