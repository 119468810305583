import { createReducer } from 'typesafe-actions';
import { SettingState } from './interface';
import { SettingAction } from './types';
import {
  SET_IMAGES,
  SET_MULTIPLE_IMAGE_URL,
  SET_SINGLE_IMAGE_URL,
  SET_MULTIPLE_IMAGE_FILE,
  SET_SINGLE_IMAGE_FILE,
  SET_MULTIPLE_IMAGE_HIDE,
  SET_SINGLE_IMAGE_HIDE,
  SET_MULTIPLE_IMAGE_ORDER,
  SET_MAX_ORDER,
} from './actions';
import { returnBannerType } from '../../../components/admin/common/CommonFunction';

const initialState: SettingState = {
  sliders: [],
  ads: [],
  bannerPC: null,
  bannerMobile: null,
  intro: null,
  introMobile: null,
  maxOrder: 1,
};

const reducer = createReducer<SettingState, SettingAction>(initialState, {
  [SET_IMAGES]: (state, { payload }) => {
    return {
      ...payload,
    };
  },
  [SET_MULTIPLE_IMAGE_URL]: (state, { payload }) => {
    const { id, value, type } = payload;

    return {
      ...state,
      [type]: state[type].map((item) =>
        item.id === id ? { ...item, url: value } : item,
      ),
    };
  },
  [SET_SINGLE_IMAGE_URL]: (state, { payload }) => {
    const { value, type } = payload;
    return {
      ...state,
      [type]:
        state[type] === null
          ? {
              id: null,
              type: returnBannerType(type),
              hide: false,
              image: {
                id: 0,
                url: '',
              },
              url: value,
              preview: null,
              file: null,
            }
          : {
              ...state[type],
              url: value,
            },
    };
  },
  [SET_MULTIPLE_IMAGE_FILE]: (state, { payload }) => {
    const { id, type, file } = payload;

    return {
      ...state,
      [type]: state[type].map((item) => {
        if (item.id === id) {
          if (item.preview !== null) {
            URL.revokeObjectURL(item.preview);
          }

          return {
            ...item,
            file: file,
            preview: URL.createObjectURL(file),
          };
        }

        return item;
      }),
    };
  },
  [SET_SINGLE_IMAGE_FILE]: (state, { payload }) => {
    const { file, type } = payload;

    if (state[type] !== null && state[type]!.preview !== null) {
      URL.revokeObjectURL(state[type]!.preview!);
    }

    return {
      ...state,
      [type]:
        state[type] === null
          ? {
              id: null,
              type: returnBannerType(type),
              hide: false,
              url: '',
              image: {
                id: 0,
                url: '',
              },
              file,
              preview: URL.createObjectURL(file),
            }
          : {
              ...state[type],
              file,
              preview: URL.createObjectURL(file),
            },
    };
  },
  [SET_MULTIPLE_IMAGE_HIDE]: (state, { payload }) => {
    const { id, hide, type } = payload;

    return {
      ...state,
      [type]: state[type].map((item) =>
        item.id === id ? { ...item, hide } : item,
      ),
    };
  },
  [SET_SINGLE_IMAGE_HIDE]: (state, { payload }) => {
    const { hide, type } = payload;

    return {
      ...state,
      [type]:
        state[type] === null
          ? {
              id: null,
              type: returnBannerType(type),
              hide,
              url: '',
              image: {
                id: 0,
                url: '',
              },
              preview: null,
              file: null,
            }
          : {
              ...state[type],
              hide,
            },
    };
  },
  [SET_MULTIPLE_IMAGE_ORDER]: (state, { payload }) => {
    const { id, value, type } = payload;
    return {
      ...state,
      [type]: state[type].map((item) =>
        item.id === id ? { ...item, order: value } : item,
      ),
    };
  },
  [SET_MAX_ORDER]: (state, { payload }) => {
    return {
      ...state,
      maxOrder: payload,
    };
  },
});

export default reducer;
