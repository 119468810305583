//메인페이지 데이터  업데이트
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { getProductCategory } from '../../modules/main';
import { ProductCategory } from '../../components/index/interface';

export default function useGetCategory() {
  const dispatch = useDispatch();

  return useCallback(
    (category: ProductCategory[]) => dispatch(getProductCategory(category)),
    [dispatch],
  );
}
