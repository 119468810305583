import React, { useState, FormEvent } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import {
  IconButton,
  Popover,
  Grid,
  Divider,
  Typography,
  Hidden,
} from '@material-ui/core';
import { CssTextField } from '../common/customStyle/TextField';
import { WhiteButton } from '../common/customStyle/Button';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import { useStylesSearch } from '../../hooks/header/useStyles';
import { GET_SEARCH_KEYWORD } from './gql';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

interface Keyword {
  id: number;
  name: string;
}

interface KeyWordResult {
  products: {
    items: Keyword[];
  };
}

const initialdata = {
  products: {
    items: [],
  },
};

//헤더 검색 아이콘 - 검색창
const HeaderSearchPopper = ({ history }: RouteComponentProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [keywordList, setKeywordList] = useState<KeyWordResult>(initialdata);
  const classes = useStylesSearch();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [getSearchKeyword, { called, loading }] = useLazyQuery(
    GET_SEARCH_KEYWORD,
    {
      onCompleted: (data) => {
        setKeywordList({ ...data });
      },
      onError: () => {
        alert('서버 요청 에러');
      },
    },
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(e.target.value);
    if (
      e.target.value.trim() !== '' &&
      e.target.value.trim().length >= 2 &&
      searchKeyword !== e.target.value.trim()
    ) {
      getSearchKeyword({
        variables: {
          value: e.target.value,
        },
      });
    }
    if (e.target.value.trim() === '') {
      setKeywordList(initialdata);
    }
  };

  const handleSearch = (e: FormEvent) => {
    e.preventDefault();
    history.push(`/search?searchKeyword=${searchKeyword.trim()}`);
    handleClose();
  };

  const handleProductLink = (productId: number) => {
    history.push(`/course/detail/${productId}`);
    handleClose();
  };

  return (
    <div>
      <Hidden mdUp>
        <IconButton id="searchbtn" aria-describedby={id} onClick={handleClick}>
          <SearchIcon />
        </IconButton>
      </Hidden>
      <Hidden smDown>
        <div
          className={classes.searchbox}
          id="searchbtn"
          aria-describedby={id}
          onClick={handleClick}
        >
          <SearchIcon className={classes.searchicon} fontSize="small" />
          <span className={classes.text}>검색</span>
        </div>
      </Hidden>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.paper}>
          <form onSubmit={handleSearch} className={classes.form}>
            <Grid container>
              <Grid item xs>
                <CssTextField
                  size="small"
                  name="searchKeyword"
                  value={searchKeyword}
                  onChange={onChange}
                  variant="outlined"
                  autoFocus
                  label="검색어 입력"
                  className={classes.input}
                  fullWidth
                  required
                  inputProps={{
                    maxLength: 100,
                  }}
                />
              </Grid>
              <Grid item>
                <WhiteButton type="submit" className={classes.searchbtn}>
                  검색
                </WhiteButton>
              </Grid>
            </Grid>
          </form>
          <Divider />
          <Grid container>
            {searchKeyword.trim() !== '' &&
              (called || loading) &&
              keywordList.products.items.length === 0 && (
                <Grid item xs={12} className={classes.result}>
                  <Typography className={classes.link}>
                    검색어와 일치하는 강의가 없습니다.
                  </Typography>
                </Grid>
              )}
            {keywordList.products.items.map((keyword: Keyword) => (
              <Grid
                item
                xs={12}
                key={keyword.id}
                className={classes.keywordList}
                onClick={() => handleProductLink(keyword.id)}
              >
                <Typography className={classes.link}>{keyword.name}</Typography>
              </Grid>
            ))}
            {keywordList.products.items.length >= 8 ? (
              <Grid
                item
                xs={12}
                className={classes.result}
                onClick={handleSearch}
              >
                <Typography className={classes.morelink}>
                  강의 검색 결과 더보기{' '}
                  <ArrowForwardIosIcon className={classes.icon} />
                </Typography>
              </Grid>
            ) : (
              <>
                {keywordList.products.items.length >= 1 && (
                  <Grid
                    item
                    xs={12}
                    className={classes.result}
                    onClick={handleSearch}
                  >
                    <Typography className={classes.morelink}>
                      강의 검색 결과 총 {keywordList.products.items.length} 건{' '}
                      <ArrowForwardIosIcon className={classes.icon} />
                    </Typography>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </div>
      </Popover>
    </div>
  );
};

export default withRouter(HeaderSearchPopper);
