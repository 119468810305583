//메인페이지 슬라이드,배너,하단배너 이미지들 업데이트
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { getMainImages, MainImageState } from '../../modules/main';

export default function useGetMainImages() {
  const dispatch = useDispatch();

  return useCallback(
    (mainImagesData: MainImageState) => dispatch(getMainImages(mainImagesData)),
    [dispatch],
  );
}
