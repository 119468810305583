import React, { useState } from 'react';

//gql
import { useQuery } from '@apollo/react-hooks';
import { GET_INDEX_DATA, GET_INDEX_IMAGES } from './gql';

//style
import { useStyles } from '../../hooks/index/useStyles';
import { Grid, Container, Hidden } from '@material-ui/core';

//component
import ReactHelmet from '../common/ReactHelmet';
import MainSlider from './mainSlide/MainSlider';
import RecommendProductList from './recommend/RecommendProductList';
import BestProductList from './best/BestProdcutList';
import CategoryBestContainer from './categoryBest/CategoryBestContainer';
import ReviewSlider from './review/ReviewSlider';
import BoardContainer from './board/BoardContainer';
import FamilySiteList from '../common/familySite/FamilySiteList';
import CenterBanner from './banner/CenterBanner';
import useGetMainData from '../../hooks/index/useGetMainData';
import BottomBanner from './banner/BottomBanner';
import useStatus from '../../hooks/index/useStatus';
import useGetMainImages from '../../hooks/index/useGetMainImages';
import BlogReviewSlider from './blogReview/BlogReviewSlide';

const IndexBox = () => {
  const classes = useStyles();
  const { mainSlides } = useStatus();
  const getMainData = useGetMainData();
  const getMainImages = useGetMainImages();
  const [isShowBlogReview, setIsShowBlogReview] = useState(false);

  //이미지들 (슬라이드, 배너, 하단배너)
  useQuery(GET_INDEX_IMAGES, {
    onCompleted: (data) => {
      getMainImages(data.indexPageImages);
    },
  });

  //데이터
  useQuery(GET_INDEX_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      getMainData(data);

      // 블로그 리뷰가 5개 미만일 경우 띄우지 않음
      if (data.blogReviews.length >= 5) {
        setIsShowBlogReview(true);
      }
    },
  });

  return (
    <div className={classes.index}>
      <ReactHelmet
        title={
          process.env.REACT_APP_INDEX_NAME === 'self'
            ? '독학동스쿨 No.1 검정고시 인강'
            : '에듀동스쿨 No.1 온라인 취미 문화 인강'
        }
      />
      {/* 메인슬라이드 */}
      <MainSlider slideList={mainSlides} />
      <Container className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={7}>
            {/* 추천강의 */}
            <RecommendProductList />
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            {/* 인기강의 */}
            <BestProductList />
          </Grid>
          {/* 중간 배너 2개 */}
          <CenterBanner />
        </Grid>
      </Container>
      {isShowBlogReview && <BlogReviewSlider />}
      <Container className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.gridcontainer}>
            {/* 카테고리별 강의리스트 */}
            <CategoryBestContainer />
          </Grid>
        </Grid>
      </Container>
      {/* 수강후기 슬라이드 */}
      <div className={classes.gridcontainer}>
        <ReviewSlider />
      </div>
      <Container className={classes.container}>
        {/* 하단 배너 */}
        <BottomBanner />
        {/* 공지사항 */}
        <BoardContainer />
        {/* 패밀리사이트 모바일버전 */}
        <Hidden mdUp>
          <FamilySiteList />
        </Hidden>
      </Container>
    </div>
  );
};

export default IndexBox;
