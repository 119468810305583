import React from 'react';
import { Typography } from '@material-ui/core';

interface Props {
  header: string;
}

const HeaderText = ({ header }: Props) => {
  return <Typography variant="h5">{header}</Typography>;
};

export default HeaderText;
