import { createAction } from 'typesafe-actions';

import { ICourse } from '../../../components/admin/product/interface';

export const SET_CONNECT_COURSE = 'product/SET_CONNECT_COURSE';
export const setConnectCourse = createAction(SET_CONNECT_COURSE)<{
  courses: ICourse[];
}>();

export const CONNECT_COURSE = 'product/CONNECT_COURSE';
export const connectCourse = createAction(CONNECT_COURSE)<{
  courses: ICourse[];
}>();

export const DISCONNECT_COURSE = 'product/DISCONNECT_COURSE';
export const disconnectCourse = createAction(DISCONNECT_COURSE)<{
  ids: number[];
}>();
