// 모바일 카테고리바로가기
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { useStylesMobileBottom } from '../../../hooks/header/useStyles';
import useStatus from '../../../hooks/index/useStatus';
import { Skeleton } from '@material-ui/lab';

interface ProductCategory {
  id: number;
  name: string;
}

const MobileBottomMenu = () => {
  const classes = useStylesMobileBottom();
  const { category } = useStatus();

  const categorySkeleton = (
    <Skeleton
      variant="text"
      width={70}
      height={20}
      className={classes.skeleton}
    />
  );

  return (
    <div className={classes.bottomBar}>
      {category.map((item: ProductCategory, i: number) => (
        <Fragment key={i}>
          <div className={classes.div}>
            <Link to={`/course/list/${item.id}`} className={classes.link}>
              <Typography color="textPrimary" className={classes.text}>
                {item.name}
              </Typography>
            </Link>
          </div>
          <div className={classes.div}>
            <div className={classes.divider} />
          </div>
        </Fragment>
      ))}
      {category.length === 0 && (
        <>
          {categorySkeleton}
          {categorySkeleton}
          {categorySkeleton}
        </>
      )}
      <div className={classes.div}>
        <Link to="/learning" className={classes.link}>
          <Typography color="textPrimary" className={classes.text}>
            내 강의실
          </Typography>
        </Link>
      </div>
    </div>
  );
};

export default MobileBottomMenu;
