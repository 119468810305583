export interface IAPIPermission {
  id: number;
  name: string;
  apiType: APIType;
  allowRead: boolean;
  allowWrite: boolean;
  allowDelete: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface IPagePermission {
  id: number;
  name: string;
  url: string;
  createdAt: Date;
  updatedAt: Date;
}

export enum APIType {
  PERMISSION = 'PERMISSION',
  USER = 'USER',
  PAUSE = 'PAUSE',
  ORDER = 'ORDER',
  PRODUCT = 'PRODUCT',
  POINT = 'POINT',
  IMAGE = 'IMAGE',
  SERVICE = 'SERVICE',
  INQUIRY = 'INQUIRY',
  CP = 'CP',
  SMS = 'SMS',
  MEMO = 'MEMO',
}

export enum APIMethodType {
  allowRead = 'allowRead',
  allowWrite = 'allowWrite',
  allowDelete = 'allowDelete',
}

export const API_TYPE_LIST: { id: number; type: APIType }[] = [
  { id: 1, type: APIType.USER },
  { id: 2, type: APIType.SERVICE },
  { id: 3, type: APIType.PRODUCT },
  { id: 4, type: APIType.POINT },
  { id: 5, type: APIType.PERMISSION },
  { id: 6, type: APIType.PAUSE },
  { id: 7, type: APIType.ORDER },
  { id: 8, type: APIType.INQUIRY },
  { id: 9, type: APIType.IMAGE },
  { id: 10, type: APIType.CP },
  { id: 11, type: APIType.MEMO },
  { id: 12, type: APIType.SMS },
];
