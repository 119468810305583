import React, { useState } from 'react';
import {
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import { Link } from 'react-router-dom';
import { useStylesNavbar } from '../../../../hooks/admin/navbar/useStyle';

const ProductNavbar = () => {
  const classes = useStylesNavbar();

  const [open, setOpen] = useState(false);

  const handleContentClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem button onClick={handleContentClick}>
        <ListItemIcon>
          <CardGiftcardIcon />
        </ListItemIcon>
        <ListItemText primary="상품 관리" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        {process.env.REACT_APP_INDEX_NAME === 'self' && (
          <List component="div" disablePadding>
            <Link to="/admin/teacher" className={classes.linkText}>
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <ListItemText primary="강사 관리" />
              </ListItem>
            </Link>
          </List>
        )}
        <List component="div" disablePadding>
          <Link to="/admin/book" className={classes.linkText}>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="교재 관리" />
            </ListItem>
          </Link>
        </List>
        <List component="div" disablePadding>
          <Link to="/admin/course" className={classes.linkText}>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="과정 관리" />
            </ListItem>
          </Link>
        </List>
        <List component="div" disablePadding>
          <Link to="/admin/product/category" className={classes.linkText}>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="카테고리 관리" />
            </ListItem>
          </Link>
        </List>
        <List component="div" disablePadding>
          <Link to="/admin/product" className={classes.linkText}>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="상품 관리" />
            </ListItem>
          </Link>
        </List>
      </Collapse>

      <Divider />
    </>
  );
};

export default ProductNavbar;
