import { Container } from '@material-ui/core';
import React, { useRef, useState, MouseEvent } from 'react';
import Slider from 'react-slick';
import useStatus from '../../../hooks/index/useStatus';
import { useStylesBlogReview } from '../../../hooks/index/useStyles';
import TitleBox from '../../common/styleComponent/TitleBox';
import BlogReviewSliderItem from './BlogReviewSliderItem';
import './blogReviewSlider.css';

const settings = {
  dots: true,
  centerMode: true,
  centerPadding: '10%',
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  swipeToSlide: true,
  autoplay: true,
  dotsClass: 'blog_reivew_button__bar',
  arrows: false,
  responsive: [
    {
      breakpoint: 1600,
      settings: { slidesToShow: 5, centerPadding: '5%' },
    },
    {
      breakpoint: 1440,
      settings: { slidesToShow: 4, centerPadding: '5%' },
    },
    {
      breakpoint: 1200,
      settings: { slidesToShow: 3, centerPadding: '10%' },
    },
    {
      breakpoint: 960,
      settings: { slidesToShow: 3, centerPadding: '5%' },
    },
    {
      breakpoint: 800,
      settings: { slidesToShow: 2, centerPadding: '10%' },
    },
    {
      breakpoint: 600,
      settings: { slidesToShow: 1, centerPadding: '20%' },
    },
  ],
};

const BlogReviewSlider = () => {
  const classes = useStylesBlogReview();
  const { blogReviews } = useStatus();
  const [swiping, setSwiping] = useState(false);
  const carouselRef = useRef<any>();

  const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleMouseUp = () => {
    setSwiping(carouselRef.current!.innerSlider.state.swiping);
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (swiping) e.stopPropagation();
  };

  return (
    <div className={classes.blogReviewSliderWrapper}>
      <Container className={classes.titleContainer}>
        <TitleBox text="블로그 후기" subtext="블로거들의 실제 체험 후기!" />
      </Container>
      <Slider {...settings} ref={carouselRef}>
        {blogReviews.map((item) => (
          <div
            key={item.id}
            onClickCapture={handleClick}
            onMouseUpCapture={handleMouseUp}
            onMouseDownCapture={handleMouseDown}
            className={classes.itemWrapper}
          >
            <BlogReviewSliderItem blogReview={item} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default BlogReviewSlider;
