//로그인 후 이동할 페이지
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { loginReturnUrl } from '../../modules/auth';

export default function useReturnUrl() {
  const dispatch = useDispatch();

  return useCallback(
    (returnUrl: string) => dispatch(loginReturnUrl(returnUrl)),
    [dispatch],
  );
}
