import { Theme, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ToggleButton from '@material-ui/lab/ToggleButton';

export const DarkButton = withStyles({
  root: {
    color: 'white',
    backgroundColor: '#5A5A5A',
    '&:hover': {
      backgroundColor: '#5A5A5A',
    },
    borderRadius: 3,
  },
})(Button);

export const GreyButton = withStyles({
  root: {
    color: 'white',
    backgroundColor: '#717171',
    '&:hover': {
      backgroundColor: '#717171',
    },
    borderRadius: 3,
  },
})(Button);

export const WhiteButton = withStyles({
  root: {
    backgroundColor: 'white',
    border: '1px solid #DDDDDD',
    borderRadius: 3,
  },
})(Button);

export const DeliveryButton = withStyles({
  root: {
    borderRadius: 0,
    backgroundColor: 'white',
    borderTop: '1px solid #E6E6E6',
  },
})(Button);

export const CartButton = withStyles({
  root: {
    color: 'white',
    border: '1px solid #888888',
    backgroundColor: '#A4A4A4',
    '&:hover': {
      backgroundColor: '#A4A4A4',
    },
    borderRadius: 3,
  },
})(Button);

export const NaverButton = withStyles({
  root: {
    color: 'white',
    backgroundColor: '#1EC800',
    '&:hover': {
      backgroundColor: '#1EC800',
    },
    borderRadius: 3,
  },
})(Button);

//주황색 그라데이션 버튼
export const GradientButton = withStyles({
  root: {
    background: 'linear-gradient(90deg, #FF7536, #FF9E36)',
    position: 'relative',
    transition: '0.2s',
    border: 0,
    borderRadius: 3,
    color: 'white',
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      background: 'linear-gradient(90deg, #FF9E36, #FF7536)',
      transform: 'translate(-50%, -50%)',
      left: '50%',
      top: '50%',
      transition: '0.2s',
      border: 0,
      borderRadius: 3,
    },
    '&:hover:after': {
      opacity: 0,
    },
    '&>span': {
      position: 'relative',
      zIndex: 1,
    },
  },
})(Button);

//주황색 그라데이션 버튼
export const GradientNoneButton = withStyles({
  root: {
    background: 'linear-gradient(90deg, #FF9E36, #FF7536)',
    position: 'relative',
    border: 0,
    borderRadius: 3,
    color: 'white',
  },
})(Button);

export const OrangeToggleButton = withStyles((theme: Theme) => ({
  root: {
    float: 'right',
    fontSize: 15,
    fontWeight: 'bold',
    width: 97,
    height: 38,
    marginLeft: 15,
    color: '#707070',
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      width: 66,
      height: 26,
      marginLeft: 11,
      border: '1px solid #DDDDDD',
      borderRadius: '5px',
    },
    '&$selected': {
      backgroundColor: '#FF9633',
      color: '#FFFFFF',
      border: 'none',
      '&:hover': {
        backgroundColor: '#FF9633',
        color: '#FFFFFF',
        border: 'none',
      },
    },
  },
  selected: {
    backgroundColor: '#FF9633',
    color: '#FFFFFF',
    border: 'none',
  },
}))(ToggleButton);
