import { createAction } from 'typesafe-actions';
import {
  IAPIPermission,
  IPagePermission,
} from '../../../components/admin/permission/interface';

export const SET_PERMISSIONS = 'permission/SET_PERMISSIONS';
export const setPermissions = createAction(SET_PERMISSIONS)<{
  apis: IAPIPermission[];
  pages: IPagePermission[];
}>();

export const ADD_PERMISSIONS = 'permission/ADD_PERMISSIONS';
export const addPermissions = createAction(ADD_PERMISSIONS)<{
  apis: IAPIPermission[];
  pages: IPagePermission[];
}>();

export const REMOVE_PERMISSIONS = 'permission/REMOVE_PERMISSIONS';
export const removePermissions = createAction(REMOVE_PERMISSIONS)<{
  apiIds: number[];
  pageIds: number[];
}>();
