import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ArticleId } from '../../help/interface';
import { useQuery } from '@apollo/react-hooks';
import { GET_CATEGORY_ARTICLES } from '../gql';
import { useStylesIndexBoard } from '../../../hooks/help/useStyles';

interface IndexBoardListProps {
  boardId?: number;
}

const BoardList = ({ boardId = 0 }: IndexBoardListProps) => {
  const classes = useStylesIndexBoard();
  const [articles, setArticles] = useState([]);
  const { error } = useQuery(GET_CATEGORY_ARTICLES, {
    variables: {
      skip: 0,
      take: 4,
      boardId: boardId,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setArticles(data.articles.items);
    },
  });

  if (error) return <>존재하지 않는 게시판입니다.</>;

  return (
    <Grid container className={classes.container}>
      {articles.map((article: ArticleId) => {
        return (
          <Grid item xs={12} key={article.id} className={classes.row}>
            <Link
              to={`/help/article?id=${article.id}&board=${boardId}`}
              className={classes.link}
            >
              <Grid container>
                <Grid item xs={9}>
                  <Typography className={classes.text} noWrap>
                    {article.title}
                  </Typography>
                </Grid>
                <Grid item xs={3} className={classes.date}>
                  <Typography className={classes.text}>
                    {article.createdAt.substring(0, 10)}
                  </Typography>
                </Grid>
              </Grid>
            </Link>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default BoardList;
