import { createReducer } from 'typesafe-actions';
import { PermissionState } from './interface';
import { PermissionAction } from './types';

import {
  SET_PERMISSIONS,
  ADD_PERMISSIONS,
  REMOVE_PERMISSIONS,
} from './actions';

const initialState: PermissionState = {
  apis: [],
  pages: [],
};

const reducer = createReducer<PermissionState, PermissionAction>(initialState, {
  [SET_PERMISSIONS]: (state, { payload: { apis, pages } }) => {
    return {
      apis,
      pages,
    };
  },
  [ADD_PERMISSIONS]: (state, { payload: { apis, pages } }) => {
    return {
      apis: [...state.apis, ...apis],
      pages: [...state.pages, ...pages],
    };
  },
  [REMOVE_PERMISSIONS]: (state, { payload: { apiIds, pageIds } }) => {
    return {
      apis: [...state.apis.filter((item) => !apiIds.includes(item.id))],
      pages: [...state.pages.filter((item) => !pageIds.includes(item.id))],
    };
  },
});

export default reducer;
