import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useCommonStyle = makeStyles(() =>
  createStyles({
    permissionDeniedContainer: {
      padding: '30px',
      maxWidth: '900px',
    },
  }),
);
