import React from 'react';
import { Card, Typography } from '@material-ui/core';
import { Product } from '../interface';
import { Link } from 'react-router-dom';
import { useStylesCard } from '../../../hooks/index/useStyles';
import numberWithCommas from '../../../util/numberWithCommas';

interface ProductCardProps {
  item: Product;
}

//추천강의, 카테고리별 인기강의 공통으로 쓰는 상품카드
const ProductCard = ({ item }: ProductCardProps) => {
  const classes = useStylesCard();

  return (
    <Card elevation={0} className={classes.card}>
      <Link to={`/course/detail/${item.id}`} className={classes.link}>
        <img
          src={item.thumbnail?.url}
          className={classes.image}
          alt="강의썸네일"
        />
        <div className={classes.content}>
          <Typography className={classes.name}>{item.name}</Typography>
          <span className={classes.price}>
            {numberWithCommas(item.price)}원
          </span>
          <span className={classes.period}>수강기간: {item.period}일</span>
        </div>
      </Link>
    </Card>
  );
};

export default ProductCard;
