import React from 'react';
import { Card, Grid, Typography, CardActionArea } from '@material-ui/core';
import { Product } from '../interface';
import { useStylesBEST } from '../../../hooks/index/useStyles';
import { Link } from 'react-router-dom';

interface BestCardProductListItemProps {
  item: Product;
  index: number;
}

const BestCardProductListItem = ({
  item,
  index,
}: BestCardProductListItemProps) => {
  const classes = useStylesBEST();

  return (
    <Card elevation={0} className={classes.card}>
      <CardActionArea className={classes.actionArea}>
        <Link to={`/course/detail/${item.id}`} className={classes.link}>
          <Grid container>
            <Grid item xs={1}>
              <div className={classes.indexdiv}>
                <Typography className={classes.indextext}>
                  {index + 1}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={7} md={8} className={classes.textbox}>
              <Typography className={classes.name}>{item.name}</Typography>
              <Typography className={classes.description} noWrap>
                {item.summary !== undefined
                  ? item.summary.replace(/<[^>]*>?/g, '\n')
                  : ''}
              </Typography>
            </Grid>
            <Grid item xs={4} md={3} className={classes.imgbox}>
              <img
                src={item.thumbnail?.url}
                alt="인기강의썸네일"
                className={classes.img}
              />
            </Grid>
          </Grid>
        </Link>
      </CardActionArea>
    </Card>
  );
};

export default BestCardProductListItem;
