import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import MobileCategoryListItem from '../../header/mobile/MobileCategoryListItem';
//gql
import { GET_CATEGORY } from '../../header/gql';
import { useQuery } from '@apollo/react-hooks';
//hooks
import useGetCategory from '../../../hooks/header/useGetCategory';
import useStatus from '../../../hooks/index/useStatus';
//style
import { useStylesSiteMap } from '../../../hooks/common/useStyles';
import {
  Grid,
  Typography,
  Divider,
  Hidden,
  List,
  ListItem,
  Collapse,
} from '@material-ui/core';
import sitemapicon from '../../../img/icon/sitemapicon.svg';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const SiteMap = () => {
  const classes = useStylesSiteMap();
  const { category } = useStatus();
  const getCategory = useGetCategory();

  //여기서 가져오는 강의카테고리가 리덕스에 저장됨.(사이트내 강의카테고리가 필요한 모든 페이지들이 참조.)
  //팝업이 아닌이상 pc,모바일 모든 페이지에 푸터가 존재하기 때문에.
  useQuery(GET_CATEGORY, {
    onCompleted: (data) => {
      if (data) getCategory(data.ProductCategory);
    },
    onError: () => console.log('get category error'),
  });

  const handlePointPopup = () => {
    window.open(
      '/mypage/point',
      '포인트내역',
      `width=550,height=700,scrollbars=1`,
    );
  };

  return (
    <>
      {/* 모바일 버전 */}
      <Hidden mdUp>
        <div className={classes.leftSide}>
          <div className={classes.overlay} />
          <div className={classes.lefticonbox}>
            <img
              src={sitemapicon}
              alt="퀵메뉴"
              className={classes.sitemapicon}
            />
            <span className={classes.lefttext}>퀵메뉴</span>
          </div>
        </div>
        <List>
          <ListItem className={classes.listitem}>
            <Link to="/intro" className={classes.link}>
              <div className={classes.divider}></div>
              <span className={classes.categoryname}>사이트소개</span>
            </Link>
          </ListItem>
          <Collapse in={true} className={classes.collapse}>
            <List>
              <Link to="/intro" className={classes.link}>
                <ListItem button>
                  <span className={classes.childname}>
                    -{' '}
                    {process.env.REACT_APP_INDEX_NAME === 'self'
                      ? '독학동'
                      : '에듀동'}
                    소개
                  </span>
                  <NavigateNextIcon className={classes.arrowicon} />
                </ListItem>
              </Link>
              <Link to="/roadmap" className={classes.link}>
                <ListItem button>
                  <span className={classes.childname}>- 수강로드맵</span>
                  <NavigateNextIcon className={classes.arrowicon} />
                </ListItem>
              </Link>
              <Link to="/review" className={classes.link}>
                <ListItem button>
                  <span className={classes.childname}>- 수강후기</span>
                  <NavigateNextIcon className={classes.arrowicon} />
                </ListItem>
              </Link>
              <Link to="/free" className={classes.link}>
                <ListItem button>
                  <span className={classes.childname}>- 무료강의</span>
                  <NavigateNextIcon className={classes.arrowicon} />
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <Divider className={classes.mobiledivider} />
          {category?.map((item) => (
            <Fragment key={item.id}>
              <MobileCategoryListItem item={item} type="sitemap" />
              <Divider className={classes.mobiledivider} />
            </Fragment>
          ))}
          <ListItem className={classes.listitem}>
            <Link to="/learning" className={classes.link}>
              <div className={classes.divider}></div>
              <span className={classes.categoryname}>내 강의실</span>
            </Link>
          </ListItem>
          <Collapse in={true} className={classes.collapse}>
            <List>
              <Link to="/mypage" className={classes.link}>
                <ListItem button>
                  <span className={classes.childname}>- 마이페이지</span>
                  <NavigateNextIcon className={classes.arrowicon} />
                </ListItem>
              </Link>
              <Link to="/mypage/order" className={classes.link}>
                <ListItem button>
                  <span className={classes.childname}>- 주문내역</span>
                  <NavigateNextIcon className={classes.arrowicon} />
                </ListItem>
              </Link>
              <ListItem button onClick={handlePointPopup}>
                <span className={classes.childname}>- 포인트내역</span>
                <NavigateNextIcon className={classes.arrowicon} />
              </ListItem>
              <Link to="/help" className={classes.link}>
                <ListItem button>
                  <span className={classes.childname}>- 고객센터</span>
                  <NavigateNextIcon className={classes.arrowicon} />
                </ListItem>
              </Link>
            </List>
          </Collapse>
        </List>
      </Hidden>
      {/* PC버전 */}
      <Hidden smDown>
        <Grid container className={classes.sitemap}>
          <Grid item xs className={classes.sitemapbox}>
            <Link to="/intro" className={classes.link}>
              <Typography className={classes.bigtext} color="textPrimary">
                사이트소개
              </Typography>
            </Link>
            <Divider className={classes.sitemapdivider} />
            <Link to="/intro" className={classes.link}>
              <Typography className={classes.smalltext} color="textPrimary">
                -{' '}
                {process.env.REACT_APP_INDEX_NAME === 'self'
                  ? '독학동'
                  : '에듀동'}
                소개
              </Typography>
            </Link>
            <Link to="/roadmap" className={classes.link}>
              <Typography className={classes.smalltext} color="textPrimary">
                - 수강로드맵
              </Typography>
            </Link>
            <Link to="/review" className={classes.link}>
              <Typography className={classes.smalltext} color="textPrimary">
                - 수강후기
              </Typography>
            </Link>
            <Link to="/free" className={classes.link}>
              <Typography className={classes.smalltext} color="textPrimary">
                - 무료강의
              </Typography>
            </Link>
          </Grid>
          {category.map((item) => (
            <Grid item xs className={classes.sitemapbox} key={item.id}>
              <Link to={`/course/list/${item.id}`} className={classes.link}>
                <Typography className={classes.bigtext} color="textPrimary">
                  {item.name}
                </Typography>
              </Link>
              <Divider className={classes.sitemapdivider} />
              {item.children?.map((child) => (
                <Link
                  to={`/course/list/${child.id}`}
                  className={classes.link}
                  key={child.id}
                >
                  <Typography className={classes.smalltext} color="textPrimary">
                    - {child.name}
                  </Typography>
                </Link>
              ))}
            </Grid>
          ))}
          <Grid item xs className={classes.mypagebox}>
            <Link to="/learning" className={classes.link}>
              <Typography className={classes.bigtext} color="textPrimary">
                내 강의실
              </Typography>
            </Link>
            <Divider className={classes.sitemapdivider} />
            <Link to="/mypage" className={classes.link}>
              <Typography className={classes.smalltext} color="textPrimary">
                - 마이페이지
              </Typography>
            </Link>
            <Link to="/mypage/order" className={classes.link}>
              <Typography className={classes.smalltext} color="textPrimary">
                - 주문내역
              </Typography>
            </Link>
            <Typography
              onClick={handlePointPopup}
              className={classes.smalltext}
              color="textPrimary"
            >
              - 포인트내역
            </Typography>
            <Link to="/help" className={classes.link}>
              <Typography className={classes.smalltext} color="textPrimary">
                - 고객센터
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
};

export default SiteMap;
