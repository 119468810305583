import styled from 'styled-components';
import { Link } from 'react-router-dom';

const RouterLink = styled(Link)`
  text-decoration: none;
`;

export default RouterLink;

export const RemoveUnderlineAnchor = styled.a`
  text-decoration: none;
`;
