import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    index: {
      marginTop: theme.spacing(10),
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(6),
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: 30,
      },
      backgroundColor: 'white',
      marginBottom: theme.spacing(10),
    },
    container: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
      backgroundColor: 'white',
    },
    banner: {
      width: '100%',
      height: 'auto',
    },
    gridcontainer: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
  }),
);

export const useStylesTitle = makeStyles((theme: Theme) =>
  createStyles({
    wrap: {
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(3),
        paddingLeft: 0,
      },
    },
    slidediv: {
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    link: {
      textDecoration: 'none',
    },
    coursecard: {
      [theme.breakpoints.up('md')]: {
        paddingRight: theme.spacing(1),
      },
    },
    morebtn: {
      paddingTop: 3,
      width: '100%',
      fontSize: 15,
      fontWeight: 'bold',
      opacity: 0.5,
      height: 35,
    },
    moreicon: {
      fontSize: 13,
    },
    div: {
      marginTop: theme.spacing(2),
    },
    skeletonrecommendcard: {
      width: '100%',
      height: 230,
      [theme.breakpoints.down('sm')]: {
        height: 190,
      },
      padding: theme.spacing(1),
    },
    skeletonbestcard: {
      width: '100%',
      height: 90,
      padding: theme.spacing(1),
    },
  }),
);

export const useStylesList = makeStyles((theme: Theme) =>
  createStyles({
    wrap: {
      paddingTop: theme.spacing(3),
    },
    title: {
      fontSize: 25,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: 22,
      },
    },
    categorywrap: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    categorycontainer: {
      border: '0.5px solid #E3DBD8',
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: 2,
      paddingBottom: 1,
      paddingTop: 1,
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(1),
      },
      overflow: 'hidden',
      height: 100,
      [theme.breakpoints.down('sm')]: {
        height: 94,
      },
    },
    titlecontiner: {
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(2),
      },
      paddingTop: theme.spacing(3),
    },
    categorylink: {
      textDecoration: 'none',
    },
    linktext: {
      fontSize: 15,
      fontWeight: 'lighter',
    },
    imgbox: {
      overflow: 'hidden',
      textAlign: 'right',
      maxWidth: 160,
      width: '100%',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    skeletoncard: {
      padding: theme.spacing(1),
      width: '100%',
      height: '100%',
      [theme.breakpoints.down('xs')]: {
        minHeight: 200,
      },
      [theme.breakpoints.down('sm')]: {
        minHeight: 250,
      },
      minHeight: 210,
    },
    skeletonimg: {
      [theme.breakpoints.down('xs')]: {
        minHeight: 110,
      },
      maxHeight: 140,
    },
  }),
);

export const useStylesSlide = makeStyles((theme: Theme) =>
  createStyles({
    slide: {
      height: 400,
      [theme.breakpoints.down('sm')]: {
        height: 240,
      },
      [theme.breakpoints.down('xs')]: {
        height: 200,
      },
    },
    titleImg: {
      position: 'relative',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      overflow: 'hidden',
      backgroundSize: 'cover',
      width: 'auto',
      height: '100%',
    },
    overlay: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: 'rgba(0,0,0,.2)',
    },
  }),
);

export const useStylesCard = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(1),
    },
    link: {
      textDecoration: 'none',
      color: '#2B2B2B',
    },
    image: {
      width: '100%',
      height: 'auto',
      transition: 'all 0.2s ease-in-out',
      '&:hover': {
        transform: 'scale(1.03)',
      },
    },
    name: {
      fontSize: 18,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
      },
      lineHeight: 1.3,
      height: '2.7em',
      textAlign: 'center',
      wordWrap: 'break-word',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      whiteSpace: 'pre-wrap',
      wordBreak: 'keep-all',
    },
    price: {
      fontSize: 12,
      fontWeight: 'bold',
      color: '#FF8936',
    },
    period: {
      marginLeft: 8,
      fontWeight: 'lighter',
      fontSize: 12,
    },
    price1: {
      textDecoration: 'line-through',
    },
    content: {
      padding: theme.spacing(1, 0, 1),
      textAlign: 'center',
    },
  }),
);

export const useStylesBEST = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(1),
      },
    },
    actionArea: {
      padding: theme.spacing(1),
    },
    link: {
      textDecoration: 'none',
      color: '#2B2B2B',
    },
    textbox: {
      paddingRight: 10,
      paddingLeft: 5,
    },
    name: {
      fontSize: 18,
      fontWeight: 'bold',
      marginBottom: 5,
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
      },
      wordBreak: 'keep-all',
    },
    indexdiv: {
      background: '#F8F8F8',
      borderRadius: 3,
      border: '1px solid #E6E6E6',
      width: 32,
      height: 32,
      textAlign: 'center',
      paddingTop: 4,
      [theme.breakpoints.down('sm')]: {
        width: 24,
        height: 24,
        paddingTop: 2,
      },
    },
    indextext: {
      fontSize: 15,
      fontWeight: 'bold',
      opacity: 0.5,
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
    },
    description: {
      fontSize: 15,
      fontWeight: 'lighter',
      [theme.breakpoints.down('sm')]: {
        fontSize: 13,
        lineHeight: 1.3,
        height: '2.5em',
        textAlign: 'left',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
      },
    },
    imgbox: {
      textAlign: 'right',
    },
    img: {
      height: 'auto',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 117,
      },
    },
  }),
);

export const useStylesReviewContainer = makeStyles((theme: Theme) =>
  createStyles({
    wrap: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(8),
      background: '#F8F8F8',
    },
    titlecontainer: {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    slide: {
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      '&:focus': {
        outline: 0,
      },
    },
    slidediv: {
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    skeleton: {
      width: '100%',
      height: 250,
      [theme.breakpoints.down('sm')]: {
        height: 300,
      },
      [theme.breakpoints.down('xs')]: {
        height: 350,
      },
      padding: theme.spacing(1),
    },
  }),
);

export const useStylesReviewCard = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
      padding: theme.spacing(3, 4, 4),
      height: '100%',
    },
    title: {
      fontSize: 25,
      lineHeight: 1.3,
      // height: '2.6em',
      // textAlign: 'left',
      // wordWrap: 'break-word',
      // display: '-webkit-box',
      // WebkitLineClamp: 2,
      // WebkitBoxOrient: 'vertical',
      // overflow: 'hidden',
      // whiteSpace: 'pre-wrap',
      // textOverflow: 'ellipsis',
      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
      },
      marginTop: theme.spacing(1),
    },
    userId: {
      fontSize: 20,
      [theme.breakpoints.down('xs')]: {
        fontSize: 15,
      },
      fontWeight: 'lighter',
      whiteSpace: 'pre-wrap',
    },
    content: {
      fontSize: 18,
      fontWeight: 'bold',
      lineHeight: 1.3,
      height: '3.9em',
      textAlign: 'left',
      wordWrap: 'break-word',
      display: '-webkit-box',
      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      marginTop: theme.spacing(1),
    },
    lecture: {
      marginTop: 5,
      fontSize: 18,
      [theme.breakpoints.down('xs')]: {
        fontSize: 15,
      },
      opacity: 0.5,
    },
    imgbox: {
      position: 'relative',
    },
    image: {
      [theme.breakpoints.up('sm')]: {
        position: 'absolute',
        margin: 'auto',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      width: '90%',
      height: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    link: {
      textDecoration: 'none',
      color: '#2B2B2B',
    },
    morelink: {
      marginTop: theme.spacing(1),
      borderTop: '1px solid #E6E6E6',
      textAlign: 'center',
    },
    moretext: {
      fontSize: 20,
      fontWeight: 'bold',
      opacity: 0.5,
    },
    moreicon: {
      fontSize: 15,
    },
    rightspan: {
      paddingLeft: 10,
    },
  }),
);

export const useStylesCenterbanner = makeStyles((theme: Theme) =>
  createStyles({
    skeletoncard: {
      height: 155,
      [theme.breakpoints.down('md')]: {
        height: 120,
      },
      [theme.breakpoints.down('sm')]: {
        height: 90,
      },
      [theme.breakpoints.down('xs')]: {
        height: 100,
      },
    },
  }),
);

export const useStylesBlogReview = makeStyles((theme: Theme) =>
  createStyles({
    blogReviewSliderWrapper: {
      paddingTop: '32px',
      paddingBottom: '32px',
    },
    titleContainer: { marginBottom: '20px' },
    itemWrapper: {
      paddingRight: 24,
      paddingLeft: 24,
      [theme.breakpoints.down('xs')]: { paddingRight: 8, paddingLeft: 8 },
    },
  }),
);

export const useStylesBlogReviewItem = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      cursor: 'pointer',
      height: '310px',
      [theme.breakpoints.down('md')]: {},
    },
    image: {
      height: '230px',
      width: '100%',
    },
    titleWrap: {
      height: '80px',
      position: 'relative',
      paddingRight: 8,
      paddingLeft: 8,
      display: 'flex',
    },
    title: {
      width: '100%',
      textAlign: 'center',
      wordBreak: 'keep-all',
      display: '-webkit-box',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
      fontStyle: 'italic',
      margin: 'auto',
      position: 'relative',
      padding: '0 7%',
    },
    frontQuote: {
      width: '20px',
      position: 'absolute',
      top: 2,
      left: -4,
    },
    backQuote: {
      width: '20px',
      position: 'absolute',
      bottom: 0,
      right: -4,
    },
  }),
);
