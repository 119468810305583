import React, { useState } from 'react';
import styled from 'styled-components';
import {
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import TocIcon from '@material-ui/icons/Toc';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Link } from 'react-router-dom';
import { useStylesNavbar } from '../../../../hooks/admin/navbar/useStyle';

const RouterLink = styled(Link)`
  text-decoration: none;
  color: black;
`;

const LogNavbar = () => {
  const classes = useStylesNavbar();

  const [open, setOpen] = useState(false);

  const handleContentClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem button onClick={handleContentClick}>
        <ListItemIcon>
          <TocIcon />
        </ListItemIcon>
        <ListItemText primary="분석" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <RouterLink to="/admin/analysis/pagelog" className={classes.linkText}>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="접속로그" />
            </ListItem>
          </RouterLink>
          <RouterLink to="/admin/analysis/funnel" className={classes.linkText}>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="최초 접속기록" />
            </ListItem>
          </RouterLink>
          <RouterLink to="/admin/analysis/selling" className={classes.linkText}>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="최근 판매 수량" />
            </ListItem>
          </RouterLink>
          <RouterLink to="/admin/analysis/cart" className={classes.linkText}>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="장바구니 내역" />
            </ListItem>
          </RouterLink>
        </List>
      </Collapse>

      <Divider />
    </>
  );
};

export default LogNavbar;
