import { Site } from './interface';

export const familySite: Site[] = [
  {
    id: 1,
    sitename: '자격동스쿨',
    subtext: '각종 자격증 시험 준비',
    url: 'https://www.passdong.com/cert/index.php?',
  },
  {
    id: 2,
    sitename: '취업동스쿨',
    subtext: '취업인적성/NCS',
    url: 'https://www.jobdong.com/job/index.php?',
  },
  {
    id: 3,
    sitename: '아이티동스쿨',
    subtext: 'IT/프로그래밍/컴퓨터일반',
    url: 'https://www.itsdong.com/it/index.php?',
  },
  {
    id: 4,
    sitename: '공시동스쿨',
    subtext: '공무원 시험 준비',
    url: 'https://www.gongsidong.com/gongsi/index.php?',
  },
  {
    id: 5,
    sitename: '교양동스쿨',
    subtext: '인문/교양/취미',
    url: 'https://www.gydong.com/gy/index.php?',
  },
  {
    id: 6,
    sitename: '영어동스쿨',
    subtext: '토익/텝스/오픽',
    url: 'https://www.05dong.com/new/index.php?',
  },
  {
    id: 7,
    sitename: '일어동스쿨',
    subtext: '일본어/JPT/JLPT',
    url: 'https://www.jpdong.com/jp/index.php?',
  },
  {
    id: 8,
    sitename: '중어동스쿨',
    subtext: '중국어/HSK',
    url: 'https://www.jkdong.com/jk/index.php?',
  },
  {
    id: 9,
    sitename: '외국어동스쿨',
    subtext: '제3외국어',
    url: 'https://www.3sdong.com/3s/index.php?',
  },
  {
    id: 10,
    sitename: '한국어동스쿨',
    subtext: '한국어/TOPIK',
    url: 'https://www.kordong.com/kor/index.php?',
  },
  {
    id: 11,
    sitename: '에어동스쿨',
    subtext: '발음/스피치',
    url: 'https://www.airdong.com/air/index.php?',
  },
  {
    id: 12,
    sitename: '위닝폰',
    subtext: '전화영어 위닝폰',
    url: 'http://www.winphone.co.kr/win/index.php?',
  },
  {
    id: 13,
    sitename: '독학동스쿨',
    subtext: '검정고시 준비',
    url: 'https://selfdong.com',
  },
  {
    id: 14,
    sitename: '에듀동스쿨',
    subtext: '문화누리카드',
    url: 'https://edudong.com',
  },
  {
    id: 15,
    sitename: '키즈동스쿨',
    subtext: '어린이 화상영어',
    url: 'https://kizdong.com',
  },
  {
    id: 16,
    sitename: '내일동스쿨',
    subtext: '내일배움카드/사업주훈련',
    url: 'https://naeildong.com/',
  },
];

export const applist: Site[] = [
  {
    id: 1,
    sitename: '도트플래너',
    subtext: '효율적인 시간관리의 시작',
    url: 'https://doteplanner.com/',
  },
  {
    id: 2,
    sitename: '도트타이머',
    subtext: '도트플래너를 앱으로 만나보세요',
    url:
      'https://play.google.com/store/apps/details?id=com.smartdongschool.dotetimer',
  },
];

export const etclist: Site[] = [
  {
    id: 1,
    sitename: '단체교육문의',
    subtext: '단체교육문의',
    url: 'http://winninglab.com/?page_id=1510',
  },
];
