import React, { useState, useRef, MouseEvent } from 'react';
import Slider from 'react-slick';
import { Grid, Hidden } from '@material-ui/core';
import ProductCard from '../categoryBest/ProductCard';
import { Product } from '../interface';
import { useStylesTitle } from '../../../hooks/index/useStyles';
import TitleBox from '../../common/styleComponent/TitleBox';
import useStatus from '../../../hooks/index/useStatus';
import { Skeleton } from '@material-ui/lab';

const settings = {
  className: 'center',
  centerPadding: '20%',
  centerMode: true,
  infinite: true,
  slidesToShow: 1,
  autoplay: true,
  autoplaySpeed: 4000,
  arrows: false,
};

const RecommendProductList = () => {
  const { recommendProducts } = useStatus();
  const classes = useStylesTitle();
  const [swiping, setSwiping] = useState(false);
  const carouselRef = useRef<any>();

  const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleMouseUp = () => {
    setSwiping(carouselRef.current!.innerSlider.state.swiping);
  };

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if (swiping) e.preventDefault();
  };

  const CourseSkeleton = (
    <Grid item sm={6} md={4} className={classes.coursecard}>
      <div className={classes.skeletonrecommendcard}>
        <Skeleton variant="rect" width="100%" height="100%" animation={false} />
      </div>
    </Grid>
  );

  return (
    <>
      <div className={classes.wrap}>
        <TitleBox text="추천 강의" subtext="강력 추천합니다!" />
        <br />
        <Hidden xsDown>
          <Grid container>
            {recommendProducts.items.map((item: Product) => (
              <Grid
                item
                sm={6}
                md={4}
                key={item.id}
                className={classes.coursecard}
              >
                <ProductCard item={item} />
              </Grid>
            ))}
            {recommendProducts.items.length === 0 && (
              <>
                {CourseSkeleton}
                {CourseSkeleton}
                {CourseSkeleton}
                {CourseSkeleton}
                {CourseSkeleton}
                {CourseSkeleton}
              </>
            )}
          </Grid>
        </Hidden>
      </div>
      <Hidden smUp>
        <Slider {...settings} ref={carouselRef}>
          {recommendProducts.items.map((item: Product) => (
            <div
              key={item.id}
              className={classes.slidediv}
              onClickCapture={handleClick}
              onMouseUpCapture={handleMouseUp}
              onMouseDownCapture={handleMouseDown}
            >
              <ProductCard item={item} />
            </div>
          ))}
          {recommendProducts.items.length === 0 && (
            <div className={classes.skeletonrecommendcard}>
              <Skeleton variant="rect" width="100%" height="100%" />
            </div>
          )}
        </Slider>
      </Hidden>
    </>
  );
};

export default RecommendProductList;
