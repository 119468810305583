import { createReducer } from 'typesafe-actions';
import { LectureState } from './interface'; //상태ts타입 가져오기
import { LectureAction } from './types'; //액션ts타입 가져오기
import {
  SELECT_PREVIEW,
  SET_VIMEO_PROGRESS,
  SET_LECTURES,
  SET_MAX_ORDER,
  ADD_LECTURES,
  DELETE_LECTURES,
  SET_UPDATE_LECTURE,
  UPDATE_LECTURE,
  CLEAR_SELECTED_LECTURES,
  UPDATE_SELECTED_LECTURES,
  UPDATE_LECTURE_INSERT_INDEX,
} from './actions'; //액션가져오기

const initialState: LectureState = {
  lectures: [],
  selectedLectures: [],
  lastSelectedLectureIndex: -1,
  lectureInsertIndex: -1,
  lectureHoverIndex: -1,
  maxOrder: 0,
  preview: 0,
  progress: {
    all: 0,
    now: 0,
    isRunning: false,
  },
  update: {
    id: 0,
    name: '',
    order: 0,
    period: 0,
    source: '0',
    courseId: 0,
  },
};

// 리듀서 만들기
const lecture = createReducer<LectureState, LectureAction>(initialState, {
  [SET_LECTURES]: (state, { payload }) => {
    return {
      ...state,
      lectures: payload,
    };
  },
  [SET_MAX_ORDER]: (state, { payload }) => {
    return {
      ...state,
      maxOrder: payload,
    };
  },
  [SELECT_PREVIEW]: (state, { payload: { preview } }) => {
    return {
      ...state,
      preview,
    };
  },
  [SET_VIMEO_PROGRESS]: (state, { payload: { all, now, isRunning } }) => {
    return {
      ...state,
      progress: {
        all,
        now,
        isRunning,
      },
    };
  },
  [ADD_LECTURES]: (state, { payload }) => {
    return {
      ...state,
      lectures: [...state.lectures, ...payload],
    };
  },
  [DELETE_LECTURES]: (state, { payload: { ids } }) => {
    return {
      ...state,
      lectures: [
        ...state.lectures.filter((item) => {
          if (!item.id) {
            return false;
          }
          return !ids.includes(item.id);
        }),
      ],
    };
  },
  [SET_UPDATE_LECTURE]: (state, { payload }) => {
    return {
      ...state,
      update: payload,
    };
  },
  [UPDATE_LECTURE]: (state, { payload }) => {
    return {
      ...state,
      lectures: [
        ...state.lectures.map((item) =>
          item.id === payload.id ? { ...payload } : item,
        ),
      ],
    };
  },
  [CLEAR_SELECTED_LECTURES]: (state) => {
    return {
      ...state,
      selectedLectures: [],
    };
  },
  [UPDATE_SELECTED_LECTURES]: (state, { payload }) => {
    return {
      ...state,
      selectedLectures: [...payload.newSelectedLectures],
      lastSelectedLectureIndex: payload.newLastSelectedIndex,
    };
  },
  [UPDATE_LECTURE_INSERT_INDEX]: (state, { payload }) => {
    return {
      ...state,
      lectureInsertIndex: payload.newInsertIndex,
      lectureHoverIndex: payload.newHoverIndex,
    };
  },
});

export default lecture;
