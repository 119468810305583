import React from 'react';
import { Product } from '../interface';
import { useStylesTitle } from '../../../hooks/index/useStyles';
import BestCardProductListItem from './BestProductListItem';
import TitleBox from '../../common/styleComponent/TitleBox';
import useStatus from '../../../hooks/index/useStatus';
import Skeleton from '@material-ui/lab/Skeleton';

const BestProductList = () => {
  const { bestProducts } = useStatus();
  const classes = useStylesTitle();

  const CourseSkeleton = (
    <div className={classes.skeletonbestcard}>
      <Skeleton variant="rect" width="100%" height="100%" animation={false} />
    </div>
  );

  return (
    <div className={classes.wrap}>
      <TitleBox text="인기 강좌 BEST 5" subtext="지금 가장 인기있는 강의!" />
      <div className={classes.div}>
        {bestProducts.items.map((item: Product, i: number) => (
          <BestCardProductListItem item={item} key={item.id} index={i} />
        ))}
        {bestProducts.items.length === 0 && (
          <>
            {CourseSkeleton}
            {CourseSkeleton}
            {CourseSkeleton}
            {CourseSkeleton}
            {CourseSkeleton}
          </>
        )}
      </div>
    </div>
  );
};

export default BestProductList;
