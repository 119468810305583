//페이지로그 기록
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const CREATE_PAGELOG = gql`
  mutation createPageLog($current: String!, $referer: String, $funnel: String) {
    createPageLog(current: $current, referer: $referer, funnel: $funnel) {
      id
    }
  }
`;

export default function useCreatePageLog() {
  const [createLogRequest] = useMutation(CREATE_PAGELOG, {
    onError: () => {
      return null;
    },
  });

  return createLogRequest;
}
