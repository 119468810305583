//유저 정보 업데이트
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { getUserInfo, UserState } from '../../modules/auth';

export default function useGetUserInfo() {
  const dispatch = useDispatch();

  return useCallback((user: UserState) => dispatch(getUserInfo({ ...user })), [
    dispatch,
  ]);
}
