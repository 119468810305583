import React, { useState } from 'react';

import {
  ListItem,
  ListItemIcon,
  Collapse,
  List,
  ListItemText,
  Divider,
} from '@material-ui/core';
import { useStylesNavbar } from '../../../../hooks/admin/navbar/useStyle';
import { OnlyRootAdminComponent } from '../../common/AdminRestrictComponent';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import RouterLink from '../../common/Links';

const PeriodNavbar = () => {
  const classes = useStylesNavbar();

  const [open, setOpen] = useState<boolean>(false);

  const onClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem button onClick={onClick}>
        <ListItemIcon>
          <AccessTimeIcon />
        </ListItemIcon>
        <ListItemText primary="수강 관리" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List disablePadding>
          <RouterLink to="/admin/period" className={classes.linkText}>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="수강 기간관리" />
            </ListItem>
          </RouterLink>
          <RouterLink to="/admin/pause" className={classes.linkText}>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="휴강 내역" />
            </ListItem>
          </RouterLink>
          <RouterLink
            to="/admin/learning/attendance"
            className={classes.linkText}
          >
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="출결 현황" />
            </ListItem>
          </RouterLink>
          <OnlyRootAdminComponent
            component={
              <RouterLink to="/admin/temporary" className={classes.linkText}>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  <ListItemText primary="임의 수강신청" />
                </ListItem>
              </RouterLink>
            }
          />
          {/* <RouterLink to="/admin/learning/log" className={classes.linkText}>
                        <ListItem button className={classes.nested}>
                            <ListItemIcon>
                                <ArrowRightIcon />
                            </ListItemIcon>
                            <ListItemText primary="수강 로그" />
                        </ListItem>
                    </RouterLink> */}
        </List>
      </Collapse>

      <Divider />
    </>
  );
};

export default PeriodNavbar;
