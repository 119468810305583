import React from 'react';
import { useStylesTitle } from '../../../hooks/common/useStyles';
import { Divider, Hidden } from '@material-ui/core';

interface TitleBoxProps {
  text: string;
  subtext?: string | null;
  size?: 'lg' | 'sm';
}

const TitleBox = ({ text, subtext = null, size = 'lg' }: TitleBoxProps) => {
  const classes = useStylesTitle();

  return (
    <div className={classes.titlebox}>
      <Divider
        orientation="vertical"
        className={size === 'lg' ? classes.divider : classes.smalldivider}
      />
      <span className={size === 'lg' ? classes.title : classes.smalltitle}>
        {text}
      </span>
      {subtext && (
        <>
          <Hidden smUp>
            <br />
          </Hidden>
          <span className={classes.subtitle}>{subtext}</span>
        </>
      )}
    </div>
  );
};

export default TitleBox;
