export interface IListProduct {
  id: number;
  name: string;
  price: number;
  category: {
    id: number;
    name: string;
  };
  contentProvider?: {
    id: number;
    name: string;
  };
  productType: ProductType;
  hide: boolean;
}

export interface IDetailProduct {
  id: number;
  name: string;
  price: number;
  realPrice?: number;
  priority: number;
  category: {
    id: number;
    name: string;
  };
  contentProviderId: number | null;
  thumbnail?: {
    id: number;
    url: string;
  };
  landing?: {
    id: number;
    url: string;
  };
  landingUrl?: string;
  period: number;
  isRecommended: boolean;
  isHit: boolean;
  allCategory?: boolean;
  summary: string;
  interestKeyword: string;
  difficulty: ProductDifficulty;
  target: string;
  method: string;
  characteristic: string;
  isPackage: boolean;
  hide: boolean;
  imageSlides: {
    id: number;
    url: string;
  }[];
  updatedId: number | null;
  bookReorderId: number | null;
  productType: ProductType;
  synchronizedAt: string | null;
  youtubeId: string | null;
}

export interface ICourse {
  id: number;
  name: string;
  price: number;
  period: number;
  teachers: {
    id: number;
    name: string;
    career?: string;
    profileImage: {
      url: string;
    };
  }[];
  relatedBooks: {
    id: number;
    title: string;
    price: number;
    realPrice: number;
    publisher: string;
    author: string;
    body: string;
    image: {
      url: string;
    };
  }[];
  createdAt: string;
}

export interface IContentProvider {
  id: number;
  name: string;
}

export interface ITeacher {
  id: number;
  name: string;
  career: string;
  slogan: string;
  field: string;
  profileImage: {
    url: string;
  };
}

export interface IBook {
  id: number;
  title: string;
  price: number;
  publisher: string;
  author: string;
  body: string;
  image: {
    url: string;
  };
}

export interface ILecture {
  id: number;
  name: string;
  period: number;
}

export interface ICourseConnect {
  courseItems: Array<number>;
}

export interface FileEx extends File {
  preview: string;
}

export interface IOriginalSlide {
  id: number;
  url: string;
}

export enum PlayColor {
  Grey = 'Grey',
  Blue = 'Blue',
  Green = 'Green',
  Magenta = 'Magenta',
  Orange = 'Orange',
  Yellow = 'Yellow',
}

export interface ThumbStrItem {
  name: string;
  size: number;
  color: string;
}

export interface PackageThumbStr {
  str1: ThumbStrItem;
  str2: ThumbStrItem;
  str3: ThumbStrItem;
  str4: ThumbStrItem;
}

export interface ArrayPackageStr {
  str: Array<ThumbStrItem>;
  bottom: string;
}

export type ProductKind = 'ALL' | 'SINGLE' | 'PACKAGE';

export enum ProductType {
  NORMAL = 'NORMAL',
  BOOKREORDER = 'BOOKREORDER',
}

export interface IProductTypeState {
  normal: boolean;
  bookReorder: boolean;
}

export enum ProductThumbnailType {
  NEW = 'NEW',
  UPLOAD = 'UPLOAD',
  EXISTING = 'EXISTING',
}

export enum ProductDifficulty {
  BEGINNER = '초급',
  INTERMEDIATE = '중급',
  ADVANCED = '고급',
}
