import React from 'react';
import { Grid } from '@material-ui/core';
import { useStylesStar } from '../../hooks/review/useStyles';
import { LectureReview } from './interface';
import star1 from '../../img/staricon/star1.png';
import star2 from '../../img/staricon/star2.png';
import star3 from '../../img/staricon/star3.png';
import star4 from '../../img/staricon/star4.png';
import star5 from '../../img/staricon/star5.png';

interface StarIconCardProps {
  review: LectureReview;
  type?: 'course' | 'review' | 'index';
}

const star: string[] = ['', star5, star4, star3, star2, star1];

const StarIconCard = ({ review, type = 'review' }: StarIconCardProps) => {
  const classes = useStylesStar();

  return (
    <Grid container className={type === 'review' ? '' : classes.starcontainer}>
      <Grid item>
        <div className={classes.box}>
          <span className={classes.text}>진행방식</span>
        </div>
        <img
          src={star[review.method]}
          alt="진행방식"
          className={
            type === 'course'
              ? classes.starcourse
              : type === 'index'
              ? classes.starindex
              : classes.starreview
          }
        />
      </Grid>
      <Grid item>
        <div className={classes.box}>
          <span className={classes.text}>콘텐츠</span>
        </div>
        <img
          src={star[review.content]}
          alt="콘텐츠"
          className={
            type === 'course'
              ? classes.starcourse
              : type === 'index'
              ? classes.starindex
              : classes.starreview
          }
        />
      </Grid>
      <Grid item>
        <div className={classes.box}>
          <span className={classes.text}>강사</span>
        </div>
        <img
          src={star[review.teacher]}
          alt="강사"
          className={
            type === 'course'
              ? classes.starcourse
              : type === 'index'
              ? classes.starindex
              : classes.starreview
          }
        />
      </Grid>
      <Grid item>
        <div className={classes.box}>
          <span className={classes.text}>교재</span>
        </div>
        <img
          src={star[review.book]}
          alt="교재"
          className={
            type === 'course'
              ? classes.starcourse
              : type === 'index'
              ? classes.starindex
              : classes.starreview
          }
        />
      </Grid>
      <Grid item>
        <div className={classes.box}>
          <span className={classes.text}>동영상</span>
        </div>
        <img
          src={star[review.video]}
          alt="동영상"
          className={
            type === 'course'
              ? classes.starcourse
              : type === 'index'
              ? classes.starindex
              : classes.starreview
          }
        />
      </Grid>
    </Grid>
  );
};

export default StarIconCard;
