import { createReducer } from 'typesafe-actions';
import { TemporaryState } from './interface';
import { TemporaryActions } from './types';
import {
  ADD_USERS,
  REMOVE_USERS,
  ADD_PRODUCTS,
  REMOVE_PRODUCTS,
} from './actions';

const initialState: TemporaryState = {
  users: [],
  products: [],
};

const reducer = createReducer<TemporaryState, TemporaryActions>(initialState, {
  [ADD_USERS]: (state, { payload }) => {
    if (
      state.users.findIndex(
        (item) => payload.findIndex((item2) => item.id === item2.id) > -1,
      ) > -1
    ) {
      return {
        ...state,
      };
    }
    return {
      ...state,
      users: [...state.users, ...payload],
    };
  },
  [REMOVE_USERS]: (state, { payload }) => {
    return {
      ...state,
      users: [...state.users.filter((item) => !payload.includes(item.id))],
    };
  },
  [ADD_PRODUCTS]: (state, { payload }) => {
    if (
      state.products.findIndex(
        (item) => payload.findIndex((item2) => item.id === item2.id) > -1,
      ) > -1
    ) {
      return {
        ...state,
      };
    }
    return {
      ...state,
      products: [...state.products, ...payload],
    };
  },
  [REMOVE_PRODUCTS]: (state, { payload }) => {
    return {
      ...state,
      products: [
        ...state.products.filter((item) => !payload.includes(item.id)),
      ],
    };
  },
});

export default reducer;
