import { createAction } from 'typesafe-actions';

import { IUser, IProduct } from './interface';

export const ADD_USERS = 'temporary/ADD_USERS';
export const addUsers = createAction(ADD_USERS)<IUser[]>();

export const REMOVE_USERS = 'temporary/REMOVE_USERS';
export const removeUsers = createAction(REMOVE_USERS)<number[]>();

export const ADD_PRODUCTS = 'temporary/ADD_PRODUCTS';
export const addProducts = createAction(ADD_PRODUCTS)<IProduct[]>();

export const REMOVE_PRODUCTS = 'temporary/REMOVE_PRODUCTS';
export const removeProducts = createAction(REMOVE_PRODUCTS)<number[]>();
