import { createAction } from 'typesafe-actions';
import {
  ChangeInfoState,
  ChangeFileState,
  ChangeHideState,
  SettingState,
} from './interface';
import {
  MultipleImageType,
  SingleImageType,
} from '../../../components/admin/setting/images/interface';

export const SET_IMAGES = 'setting/SET_IMAGES';
export const setImages = createAction(SET_IMAGES)<SettingState>();

export const SET_MULTIPLE_IMAGE_URL = 'setting/SET_MULTIPLE_IMAGE_URL';
export const setMultipleImageURL = createAction(SET_MULTIPLE_IMAGE_URL)<
  ChangeInfoState<MultipleImageType>
>();

export const SET_MULTIPLE_IMAGE_ORDER = 'setting/SET_MULTIPLE_IMAGE_ORDER';
export const setMultipleImageOrder = createAction(SET_MULTIPLE_IMAGE_ORDER)<
  ChangeInfoState<MultipleImageType>
>();

export const SET_SINGLE_IMAGE_URL = 'setting/SET_SINGLE_IMAGE_URL';
export const setSingleImageURL = createAction(SET_SINGLE_IMAGE_URL)<
  ChangeInfoState<SingleImageType>
>();

export const SET_MULTIPLE_IMAGE_FILE = 'setting/SET_MULTIPLE_IMAGE_FILE';
export const setMultipleImagesFile = createAction(SET_MULTIPLE_IMAGE_FILE)<
  ChangeFileState<MultipleImageType>
>();

export const SET_SINGLE_IMAGE_FILE = 'setting/SET_SINGLE_IMAGE_FILE';
export const setSingleImageFile = createAction(SET_SINGLE_IMAGE_FILE)<
  ChangeFileState<SingleImageType>
>();

export const SET_MULTIPLE_IMAGE_HIDE = 'setting/SET_MULTIPLE_IMAGE_HIDE';
export const setMultipleImagesHide = createAction(SET_MULTIPLE_IMAGE_HIDE)<
  ChangeHideState<MultipleImageType>
>();

export const SET_SINGLE_IMAGE_HIDE = 'setting/SET_SINGLE_IMAGE_HIDE';
export const setSingleImageHide = createAction(SET_SINGLE_IMAGE_HIDE)<
  ChangeHideState<SingleImageType>
>();

export const SET_MAX_ORDER = 'setting/SET_MAX_ORDER';
export const setMaxOrder = createAction(SET_MAX_ORDER)<number>();
