export enum CardType {
  CREDIT = 'CREDIT',
  CHECK = 'CHECK',
  GIFT = 'GIFT',
  UNKNOWN = 'UNKNOWN',
}
export enum PayMentMethod {
  CREDIT_CARD = 'CREDIT_CARD',
  VOUCHER_CARD = 'VOUCHER_CARD',
  VIRTUAL_ACCOUNT = 'VIRTUAL_ACCOUNT',
  REALTIME_TRANSFER = 'REALTIME_TRANSFER',
  FREE = 'FREE',
}

export enum PaymentState {
  PENDING = 'PENDING',
  PENDING_VA_TRANSFER = 'PENDING_VA_TRANSFER',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
  CANCELED_SITE = 'CANCELED_SITE',
  COMPLETED = 'COMPLETED',
  REFUNDED = 'REFUNDED',
  REFUND_REQUESTED = 'REFUND_REQUESTED',
}
//결제상태
export const paymentStateTranslation = (state: PaymentState) => {
  switch (state) {
    case PaymentState.COMPLETED:
      return '결제완료';
    case PaymentState.REFUNDED:
      return '환불완료';
    case PaymentState.PENDING:
      return '결제대기';
    case PaymentState.PENDING_VA_TRANSFER:
      return '무통장 입금 대기';
    case PaymentState.FAILED:
      return '결제실패';
    case PaymentState.CANCELED:
      return '결제전취소';
    case PaymentState.CANCELED_SITE:
      return '결제후취소';
    case PaymentState.REFUND_REQUESTED:
      return '환불요청';
    default:
      return '확인불가';
  }
};

//결제수단
export const paymentMethodTranslation = (method: PayMentMethod) => {
  switch (method) {
    case PayMentMethod.VIRTUAL_ACCOUNT:
      return '무통장 입금';
    case PayMentMethod.CREDIT_CARD:
      return '신용카드';
    case PayMentMethod.REALTIME_TRANSFER:
      return '실시간 계좌 이체';
    case PayMentMethod.FREE:
      return '무료결제';
    case PayMentMethod.VOUCHER_CARD:
      return '평생교육바우처';
    default:
      return '확인불가';
  }
};

//카드 타입
export const cardTypeTranslation = (cardType: CardType) => {
  switch (cardType) {
    case CardType.CREDIT:
      return '신용';
    case CardType.CHECK:
      return '체크';
    case CardType.GIFT:
      return '기프트';
    default:
      return '확인불가';
  }
};

//무통장 은행
export const bankTranslation = (bank: string) => {
  switch (bank) {
    case '003':
      return '기업';
    case '006':
      return '국민';
    case '011':
      return '농협';
    case '026':
      return '신한';
    case '081':
      return 'KEB하나';
    case '071':
      return '우체국';
    case '039':
      return '경남';
    case '020':
      return '우리';
    case '032':
      return '부산';
    case '007':
      return '수협';
    case '031':
      return '대구';
    case '03':
      return '기업';
    case '06':
      return '국민';
    case '11':
      return '농협';
    case '26':
      return '신한';
    case '81':
      return 'KEB하나';
    case '71':
      return '우체국';
    case '39':
      return '경남';
    case '20':
      return '우리';
    case '32':
      return '부산';
    case '07':
      return '수협';
    case '31':
      return '대구';
    default:
      return '확인불가';
  }
};

//카드사
export const cardSaTranslation = (cardsa: string) => {
  const cardCompanyCode = cardsa.substr(0, 2);

  switch (cardCompanyCode) {
    case '11':
      return '국민';
    case '15':
      return '카카오뱅크';
    case '21':
      return '하나(외환)';
    case '30':
      return 'KDB산업체크';
    case '31':
      return '비씨';
    case '33':
      return '우리';
    case '34':
      return '수협';
    case '35':
      return '전북';
    case '36':
      return '씨티';
    case '37':
      return '우체국체크';
    case '38':
      return '새마을금고체크';
    case '39':
      return '저축은행체크';
    case '41':
      return '신한';
    case '42':
      return '제주';
    case '46':
      return '광주';
    case '51':
      return '삼성';
    case '61':
      return '현대';
    case '62':
      return '신협체크';
    case '71':
      return '롯데';
    case '91':
      return 'NH';
    case '3C':
      return '중국은련';
    case '4J':
      return '해외JCB';
    case '4M':
      return '해외MASTER';
    case '4V':
      return '해외VISA';
    case '6D':
      return '해외DINERS';
    case '6I':
      return '해외DISCOVER';
    default:
      return '확인불가';
  }
};

export const checkCardAndBCCompany = (
  cardType: CardType | null,
  company: string,
) => {
  if (cardType === null) {
    return false;
  }

  if (cardType === CardType.CHECK && company === '31000') {
    return true;
  }

  return false;
};
