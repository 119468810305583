import React from 'react';
import { RouteComponentProps, Switch, Route } from 'react-router-dom';
import useRestrictPermission from '../../hooks/admin/common/useRestrictPermission';
import PermissionDeniedPage from '../../components/admin/common/PermissionDenied';
import CPMailManageList from '../../components/admin/CPMailManage/CPMailManage';

const CPMailManagePage = ({ match }: RouteComponentProps) => {
  const { pass } = useRestrictPermission(match.url);

  return pass ? (
    <Switch>
      <Route exact component={CPMailManageList} path={`${match.url}`} />
    </Switch>
  ) : (
    <PermissionDeniedPage />
  );
};

export default CPMailManagePage;
