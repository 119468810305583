import React from 'react';
import usePermissionStatus from '../../../hooks/auth/useStatus';
import {
  checkPageAccessPermission,
  checkAPIAccessPermission,
} from './CommonFunction';
import { APIType, APIMethodType } from '../permission/interface';

interface IOnlyRootAdminProps {
  component: React.ReactNode;
}

const OnlyRootAdminComponent = ({ component }: IOnlyRootAdminProps) => {
  const { permission } = usePermissionStatus();

  if (permission.name === '관리자' || !permission.useSpecificPermission) {
    return <>{component}</>;
  } else {
    return <></>;
  }
};

interface IAdminPageRestrictProps {
  path: string;
  component: React.ReactNode;
  isOnlyRootAdmin?: boolean;
}

const AdminPageRestrictComponent = ({
  path,
  component,
  isOnlyRootAdmin,
}: IAdminPageRestrictProps) => {
  const { permission } = usePermissionStatus();

  // 최고 관리자면 그냥 패스
  if (permission.name === '관리자' || !permission.useSpecificPermission) {
    return <>{component}</>;
  }

  if (isOnlyRootAdmin) {
    return <></>;
  }

  if (checkPageAccessPermission(path, permission.pagePermissions)) {
    return <>{component}</>;
  } else {
    return <></>;
  }
};

interface IAdminAPIRestrictProps {
  apiType: APIType;
  method: APIMethodType;
  component: React.ReactNode;
  isOnlyRootAdmin?: boolean;
}

const AdminAPIRestrictComponent = ({
  apiType,
  method,
  component,
  isOnlyRootAdmin,
}: IAdminAPIRestrictProps) => {
  const { permission } = usePermissionStatus();

  // 최고 관리자면 그냥 패스
  if (permission.name === '관리자' || !permission.useSpecificPermission) {
    return <>{component}</>;
  }

  if (isOnlyRootAdmin) {
    return <></>;
  }

  if (checkAPIAccessPermission(apiType, method, permission.apiPermissions)) {
    return <>{component}</>;
  } else {
    return <></>;
  }
};

export {
  OnlyRootAdminComponent,
  AdminPageRestrictComponent,
  AdminAPIRestrictComponent,
};
