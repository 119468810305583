import { useQuery } from '@apollo/react-hooks';
import { useState } from 'react';
import { GET_USER_CPID } from '../admin/useUser';

export default function useGetCPId() {
  const [cpId, setCPId] = useState(0);

  useQuery(GET_USER_CPID, {
    fetchPolicy: 'no-cache',
    onCompleted: (result) => {
      if (result && result.contentProvider != null) {
        const cpCheck = result.contentProvider.some((cp: any) => {
          if (cp.user !== null) {
            if (cp.user.accountId == result.user.accountId) setCPId(cp.id);
            return cp.user.accountId == result.user.accountId;
          }
          return false;
        });

        if (!cpCheck) setCPId(-1);
      } else {
        console.log('Access denied');
      }
    },
    onError: (error) => console.log(error.message),
  });

  return cpId;
}
