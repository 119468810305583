import gql from 'graphql-tag';

const LATEST_ORDER_TIME = gql`
  query orders {
    orders(
      where: [
        {
          payment: {
            state: {
              operator: IN
              value: [
                PENDING_VA_TRANSFER
                FAILED
                CANCELED
                CANCELED_SITE
                COMPLETED
                REFUND_REQUESTED
                REFUNDED
              ]
            }
          }
        }
      ]
      getAllUser: true
      pagination: { take: 1 }
    ) {
      items {
        createdAt
      }
    }
  }
`;

export default LATEST_ORDER_TIME;
