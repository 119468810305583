import gql from 'graphql-tag';

const LIST_QUERY = gql`
  query category {
    board {
      id
      name
    }
    FAQCategory {
      id
      name
    }
    inquiryCategory {
      id
      name
      createdAt
      updatedAt
    }
    inquiries(
      getAllUser: true
      pagination: { getAll: true }
      where: [{ hasReply: { operator: EQUAL, value: [false] } }]
    ) {
      items {
        categoryId
      }
    }
  }
`;

export default LIST_QUERY;
