// 패밀리사이트 링크모음
import React from 'react';
import { useStylesFamilySite } from '../../../hooks/common/useStyles';
import { Grid, Typography, Hidden } from '@material-ui/core';
import DvrIcon from '@material-ui/icons/Dvr';
import sample from '../../../img/backgroundimg/familysiteimg.png';
import samplemobile from '../../../img/backgroundimg/familysiteimgmobile.png';
import FamilySiteListItem from './FamilySiteListItem';
import { familySite, applist, etclist } from './SiteLink';

const FamilySiteList = () => {
  const classes = useStylesFamilySite();

  return (
    <Grid container className={classes.paper}>
      <Hidden smDown>
        <Grid
          item
          className={classes.leftSide}
          style={{ backgroundImage: `url(${sample})` }}
        >
          <div className={classes.overlay} />
          <div className={classes.lefticonbox}>
            <DvrIcon className={classes.lefticon} />
            <Typography className={classes.lefttext}>패밀리 사이트</Typography>
            <Typography className={classes.lefttext}>바로가기</Typography>
          </div>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid
          item
          xs={12}
          className={classes.leftSide}
          style={{ backgroundImage: `url(${samplemobile})` }}
        >
          <div className={classes.overlay} />
          <div className={classes.lefticonbox}>
            <Typography className={classes.lefttext}>
              <DvrIcon /> 패밀리 사이트 바로가기
            </Typography>
          </div>
        </Grid>
      </Hidden>
      <Grid item xs={12} md>
        <Grid container spacing={2} className={classes.sitecontainer}>
          <FamilySiteListItem siteList={familySite} text="인터넷 강의" />
          <FamilySiteListItem siteList={applist} text="플래너 및 타이머" />
          <FamilySiteListItem siteList={etclist} text="기타" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FamilySiteList;
