import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const UPDATE_CP_MAIL = gql`
  mutation updateContentProviderMail(
    $id: Int!
    $userId: Int
    $cpName: String
    $email: String
  ) {
    updateContentProviderMail(
      id: $id
      userId: $userId
      cpName: $cpName
      email: $email
    )
  }
`;

export function useUpdateCPMail(refetch?: () => void) {
  const [updateCPMailRequest] = useMutation(UPDATE_CP_MAIL, {
    onCompleted: (data) => {
      if (data.updateContentProviderMail) {
        if (refetch) {
          alert('수정되었습니다.');
          refetch();
        }
      }
    },
    onError: () => {
      alert('server request error !');
    },
  });

  return updateCPMailRequest;
}
