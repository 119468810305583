import React from 'react';
import { Hidden } from '@material-ui/core';
import useStatus from '../../../hooks/index/useStatus';

const CenterBanner = () => {
  const { siteBannerPC, siteBannerMobile } = useStatus();

  return (
    <>
      {siteBannerPC && (
        <Hidden xsDown>
          <img
            src={siteBannerPC?.image.url}
            alt="하단배너"
            width="100%"
            height="auto"
          />
        </Hidden>
      )}
      {siteBannerMobile && (
        <Hidden smUp>
          <img
            src={siteBannerMobile?.image.url}
            alt="모바일하단배너"
            width="100%"
            height="auto"
          />
        </Hidden>
      )}
    </>
  );
};

export default CenterBanner;
