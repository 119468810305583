import React from 'react';
import { Category } from '../../course/interface';
import useStatus from '../../../hooks/index/useStatus';
import PCCategoryListItem from './PCCategoryListItem';
import { Skeleton } from '@material-ui/lab';
import { Grid } from '@material-ui/core';

const PCCategoryList = () => {
  const { category } = useStatus();

  return (
    <Grid container spacing={2}>
      {category.map((item: Category) => (
        <Grid item key={item.id}>
          <PCCategoryListItem item={item} />
        </Grid>
      ))}
      {category.length === 0 && (
        <>
          <Grid item>
            <Skeleton
              variant="text"
              width={120}
              height={30}
              style={{ marginLeft: 10, marginRight: 20 }}
            />
          </Grid>
          <Grid item>
            <Skeleton
              variant="text"
              width={120}
              height={30}
              style={{ marginRight: 20 }}
            />
          </Grid>
          <Grid item>
            <Skeleton
              variant="text"
              width={120}
              height={30}
              style={{ marginRight: 20 }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default PCCategoryList;
