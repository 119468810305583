export enum FileType {
  MAIN_SLIDE = 'MAIN_SLIDE',
  MAIN_AD = 'MAIN_AD',
  SITE_BANNER_PC = 'SITE_BANNER_PC',
  SITE_BANNER_MOBILE = 'SITE_BANNER_MOBILE',
  INTRO = 'INTRO',
  INTRO_MOBILE = 'INTRO_MOBILE',
}

export type MultipleImageType = 'sliders' | 'ads';

export type SingleImageType =
  | 'bannerPC'
  | 'bannerMobile'
  | 'intro'
  | 'introMobile';

export interface FileEx {
  file: File;
  preview: string;
  url: string;
}

export interface IUserIndexImage {
  file: File;
  preview: string;
  url: string;
  order: number;
}

export interface IIndexImage<T extends MultipleImageType | SingleImageType> {
  id: T extends MultipleImageType ? number : number | null;
  type: MultipleImageType | SingleImageType;
  hide: boolean;
  url: string;
  image: T extends MultipleImageType
    ? {
        id: number;
        url: string;
      }
    : {
        id: number;
        url: string;
      } | null;
  file: File | null;
  preview: string | null;
  order: T extends MultipleImageType ? number : undefined;
}
