import { createReducer } from 'typesafe-actions';
import { MainState } from './interface'; //상태ts타입 가져오기
import { MainAction } from './types'; //액션ts타입 가져오기
import {
  GET_MAIN_DATA,
  GET_MAIN_IMAGES,
  GET_PRODUCT_CATEGORY,
  GET_BOARD_CATEGORY,
} from './actions'; //액션가져오기

const initialState: MainState = {
  category: [],
  recommendProducts: {
    items: [],
  },
  bestProducts: {
    items: [],
  },
  board: [],
  blogReviews: [],
  lectureReviews: {
    items: [],
  },
  mainSlides: [],
  mainAds: [],
  siteBannerPC: null,
  siteBannerMobile: null,
};

const main = createReducer<MainState, MainAction>(initialState, {
  [GET_MAIN_DATA]: (state, { payload: mainData }) => {
    return {
      ...state,
      recommendProducts: {
        items: mainData.recommendProducts.items,
      },
      bestProducts: {
        items: mainData.bestProducts.items,
      },
      board: mainData.board,
      blogReviews: mainData.blogReviews,
      lectureReviews: {
        items: mainData.lectureReviews.items,
      },
    };
  },
  [GET_MAIN_IMAGES]: (state, { payload: mainData }) => {
    return {
      ...state,
      mainSlides: mainData.mainSlides,
      mainAds: mainData.mainAds,
      siteBannerPC: mainData.siteBannerPC,
      siteBannerMobile: mainData.siteBannerMobile,
    };
  },
  [GET_PRODUCT_CATEGORY]: (state, { payload: productCategory }) => {
    return {
      ...state,
      category: productCategory,
    };
  },
  [GET_BOARD_CATEGORY]: (state, { payload: board }) => {
    return {
      ...state,
      board: board,
    };
  },
});

export default main;
