//가장 나중에 거가 적용됨
import React from 'react';
import { Helmet } from 'react-helmet';

interface HelmetProps {
  keywords?: string;
  description?: string;
  title: string;
  favicon?: string;
}

const ReactHelmet = ({
  title,
  keywords = '',
  description = '',
  favicon = '',
}: HelmetProps) => {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      <meta property="og:title" content={title} />
      {favicon && <meta property="og:image" content={favicon} />}
      <meta
        property="og:site_name"
        content={
          process.env.REACT_APP_INDEX_NAME === 'self'
            ? '독학동스쿨 No.1 검정고시 인강'
            : '에듀동스쿨 No.1 온라인 취미 문화 인강'
        }
      />
      {description && <meta property="og:description" content={description} />}

      <meta name="twitter:title" content={title} />
      {description && <meta name="twitter:description" content={description} />}
      {favicon && <meta name="twitter:image" content={favicon} />}
      <meta name="twitter:card" content="summary" />
    </Helmet>
  );
};

export default ReactHelmet;

export const ReactHelmetScript = () => {
  return (
    <>
      {window.location.hostname !== 'localhost' && (
        <Helmet>
          {process.env.REACT_APP_INDEX_NAME === 'self' && (
            <script
              src="script/facebook_self.js"
              type="text/javascript"
            ></script>
          )}
          {process.env.REACT_APP_INDEX_NAME === 'edu' && (
            <script
              src="script/facebook_edu.js"
              type="text/javascript"
            ></script>
          )}
        </Helmet>
      )}
    </>
  );
};
