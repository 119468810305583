import { createReducer } from 'typesafe-actions';
import { OrderState } from './interface';
import { OrderAction } from './types';
import {
  GET_ALL_INFO,
  UPDATE_PRICE,
  UPDATE_USER_INFO,
  UPDATE_PHONE,
  UPDATE_ADDRESS_INFO,
  ADDRESS_CHANGE_CHECK,
  UDATE_PAYMENT_METHOD,
  UPDATE_VOUCHER_CARD,
} from './actions';
import { PayMentMethod } from '../../util/PaymentTranslation';

const initialState: OrderState = {
  orderPrice: {
    totalPrice: 0,
    point: 0,
  },
  info: {
    userInfo: {
      name: '',
      email: '',
      message: '',
    },
    phone: {
      phone1: '010',
      phone2: '',
      phone3: '',
    },
    addressInfo: {
      postCode: '',
      address: '',
      addressDetail: '',
    },
    checked: false,
  },
  voucherCard: {
    customerName: '',
    cardNumber: '',
    cardExpirationYear: '',
    cardExpirationMonth: '',
    customerIdentityNumber: '',
  },
  paymentMethod: PayMentMethod.VIRTUAL_ACCOUNT,
};

const order = createReducer<OrderState, OrderAction>(initialState, {
  [UPDATE_PRICE]: (state, { payload }) => {
    return {
      ...state,
      orderPrice: {
        ...payload,
      },
    };
  },
  [GET_ALL_INFO]: (state, { payload }) => {
    return {
      ...state,
      info: {
        ...payload,
      },
    };
  },
  [UPDATE_USER_INFO]: (state, { payload }) => {
    return {
      ...state,
      info: {
        ...state.info,
        userInfo: {
          ...payload,
        },
      },
    };
  },
  [UPDATE_PHONE]: (state, { payload }) => {
    return {
      ...state,
      info: {
        ...state.info,
        phone: {
          ...payload,
        },
      },
    };
  },
  [UPDATE_ADDRESS_INFO]: (state, { payload }) => {
    return {
      ...state,
      info: {
        ...state.info,
        addressInfo: {
          ...payload,
        },
      },
    };
  },
  [ADDRESS_CHANGE_CHECK]: (state, { payload }) => {
    return {
      ...state,
      info: {
        ...state.info,
        checked: payload,
      },
    };
  },
  [UDATE_PAYMENT_METHOD]: (state, { payload }) => {
    return {
      ...state,
      paymentMethod: payload,
    };
  },
  [UPDATE_VOUCHER_CARD]: (state, { payload }) => {
    return {
      ...state,
      voucherCard: {
        ...payload,
      },
    };
  },
});

export default order;
