import React, { useState, useRef } from 'react';
import { MenuList, Paper, Grow, Popper, MenuItem } from '@material-ui/core';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Category } from '../../course/interface';
import { useStylesPCList } from '../../../hooks/header/useStyles';

interface PCCategoryListItemProps extends RouteComponentProps {
  item: Category;
}

const PCCategoryListItem = ({ item, history }: PCCategoryListItemProps) => {
  const classes = useStylesPCList();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLink = () => {
    history.push(`/course/list/${item.id}`);
  };

  return (
    <>
      <div
        className={classes.linkbox}
        onMouseOver={handleOpen}
        onMouseOut={handleClose}
        ref={anchorRef}
        onClick={handleLink}
      >
        <span className={classes.categoryName}>{item.name}</span>
        {open && <div className={classes.arrow}></div>}
      </div>
      {item.children?.length > 0 && (
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          transition
          className={classes.popper}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: 'center bottom' }}
            >
              <Paper
                className={classes.paper}
                onMouseOver={handleOpen}
                onMouseOut={handleClose}
              >
                <MenuList id="split-button-menu">
                  {item.children?.map((child: Category, i: number) => (
                    <div key={child.id}>
                      {i !== 0 && <div className={classes.divider} />}
                      <Link
                        to={`/course/list/${child.id}`}
                        key={child.id}
                        className={classes.link}
                      >
                        <MenuItem>{child.name}</MenuItem>
                      </Link>
                    </div>
                  ))}
                </MenuList>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </>
  );
};

export default withRouter(PCCategoryListItem);
