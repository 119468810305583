import React, { useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Grid, Typography } from '@material-ui/core';
import { useCommonStyle } from '../../../hooks/admin/common/useStyle';
import { OrangeButton } from './Button';

interface Props extends RouteComponentProps {}

const PermissionDenied = ({ history }: Props) => {
  const classes = useCommonStyle();

  const goToBack = useCallback(() => {
    history.goBack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={4} className={classes.permissionDeniedContainer}>
      <Grid item xs={12}>
        <Typography variant="h4" style={{ fontWeight: 'bold' }}>
          권한이 없습니다.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <OrangeButton variant="contained" onClick={goToBack}>
          뒤로 돌아가기
        </OrangeButton>
      </Grid>
    </Grid>
  );
};

export default withRouter(PermissionDenied);
