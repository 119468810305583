import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import {
  selectBoard,
  setInquiryCount,
  BoardState,
} from '../../../modules/admin/board';

export const useSelectBoard = () => {
  const dispatch = useDispatch();

  return useCallback(
    (id: number, name: string) => dispatch(selectBoard({ id, name })),
    [dispatch],
  );
};

export const useSetInquiryCount = () => {
  const dispatch = useDispatch();

  return useCallback(
    (count: BoardState['count']) => dispatch(setInquiryCount({ count })),
    [dispatch],
  );
};
