//모바일 - 오른쪽 네비 과정 카테고리
import React, { useState } from 'react';
import { ListItem, Collapse, List, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useStylesCategoryItem } from '../../../hooks/header/useStyles';
import { Category } from '../../course/interface';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

interface MobileCategoryListItemProps {
  item: Category;
  type?: 'rightnav' | 'sitemap';
}

const MobileCategoryListItem = ({
  item,
  type = 'rightnav',
}: MobileCategoryListItemProps) => {
  const classes = useStylesCategoryItem();
  const [open, setOpen] = useState(type === 'sitemap' ? true : false); // 하위 카테고리 드롭다운

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem className={classes.listitem}>
        <Grid container>
          <Grid item xs>
            <Link to={`/course/list/${item.id}`} className={classes.link}>
              {type === 'sitemap' && <div className={classes.divider}></div>}
              <span className={classes.categoryname}>{item.name}</span>
            </Link>
          </Grid>
          {type !== 'sitemap' && item.children.length !== 0 && (
            <Grid item onClick={handleClick} className={classes.arrowicon}>
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Grid>
          )}
        </Grid>
      </ListItem>
      <Collapse
        in={type === 'sitemap' ? true : open}
        timeout="auto"
        unmountOnExit
        className={classes.collapse}
      >
        <List>
          {item.children?.map((child: Category) => (
            <Link
              to={`/course/list/${child.id}`}
              className={classes.link}
              key={child.id}
            >
              <ListItem button>
                <span className={classes.childname}>- {child.name}</span>
                {type === 'sitemap' && (
                  <NavigateNextIcon className={classes.arrowicon} />
                )}
              </ListItem>
            </Link>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default MobileCategoryListItem;
