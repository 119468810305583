import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(2),
      },
    },
    title: {
      [theme.breakpoints.up('sm')]: {
        fontSize: 40,
      },
      fontSize: 22,
    },
    title1: {
      fontWeight: 'lighter',
    },
    title2: {
      fontWeight: 'bold',
    },
  }),
);

export default useStyles;

export const useStylesList = makeStyles((theme: Theme) =>
  createStyles({
    btn: {
      width: '100%',
      height: 45,
      borderRadius: 0,
      fontSize: 15,
      fontWeight: 'bolder',
      [theme.breakpoints.up('sm')]: {
        fontSize: 21,
      },
    },
    link: {
      textDecoration: 'none',
    },
    pagination: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(3),
    },
  }),
);

export const useStylesBest = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      border: '1px solid #E6E6E6',
    },
    backgimg: {
      overflow: 'hidden',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        height: 80,
      },
      position: 'relative',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    overlay: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: 'rgba(0,0,0,.5)',
    },
    besttext: {
      fontSize: 25,
      fontWeight: 'bold',
      color: 'white',
      whiteSpace: 'pre-wrap',
      [theme.breakpoints.up('md')]: {
        lineHeight: '130%',
      },
    },
    iconbox: {
      zIndex: 1,
      position: 'absolute',
      top: '25%',
      bottom: 0,
      right: 0,
      left: 0,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        top: '30%',
      },
    },
    icon: {
      width: '100%',
      maxWidth: 100,
      height: 'auto',
    },
    label: {
      fontSize: 20,
      fontWeight: 'lighter',
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
      },
    },
    contentbox: {
      padding: theme.spacing(7, 4, 8),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
      height: '100%',
    },
    divider: {
      border: '1px solid #DDDDDD',
      marginRight: theme.spacing(2),
    },
    reviewtitle: {
      fontSize: 30,
      fontWeight: 'lighter',
      whiteSpace: 'pre-wrap',
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
      },
    },
    reviewcontent: {
      fontSize: 20,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
      },
    },
    reviewcourse: {
      fontSize: 16,
      fontWeight: 'lighter',
      opacity: 0.7,
      [theme.breakpoints.down('sm')]: {
        fontSize: 13,
      },
    },
    rightspan: {
      paddingLeft: 10,
    },
  }),
);

export const useStylesItem = makeStyles((theme: Theme) =>
  createStyles({
    reviewContainer: {
      marginTop: theme.spacing(3),
    },
    premiumreview: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    title: {
      lineHeight: 1.2,
      fontWeight: 'lighter',
      fontSize: 20,
      [theme.breakpoints.up('sm')]: {
        fontSize: 30,
      },
    },
    label: {
      fontWeight: 'lighter',
      fontSize: 15,
      [theme.breakpoints.up('sm')]: {
        fontSize: 20,
      },
    },
    premiumcontent: {
      fontSize: 20,
      lineHeight: 1.3,
      height: '3.8em',
      fontWeight: 'bold',
      textAlign: 'left',
      wordWrap: 'break-word',
      display: '-webkit-box',
      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      [theme.breakpoints.down('xs')]: {
        fontSize: 15,
      },
    },
    content: {
      fontSize: 20,
      fontWeight: 'bold',
      [theme.breakpoints.down('xs')]: {
        fontSize: 15,
      },
    },
    course: {
      fontSize: 18,
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
      },
      opacity: 0.9,
      fontWeight: 'lighter',
    },
    ratediv: {
      alignItems: 'flex-end',
      display: 'flex',
    },
    arrowdiv: {
      alignItems: 'center',
      display: 'flex',
    },
    arrowicon: {
      border: '1px solid #E6E6E6',
      borderRadius: '3px',
    },
    moretext: {
      fontSize: 18,
      color: '#FF6136',
    },
    arrowRight: {
      fontSize: 14,
      color: '#FF6136',
    },
    img: {
      width: 226,
      height: 226,
    },
    divider: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
    rightspan: {
      paddingLeft: 10,
    },
  }),
);

export const useStylesStar = makeStyles((theme: Theme) =>
  createStyles({
    starcontainer: {
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-end',
      },
    },
    starreview: {
      maxWidth: 65,
      [theme.breakpoints.down('sm')]: {
        maxWidth: 60,
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: 50,
      },
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    starindex: {
      maxWidth: 50,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    starcourse: {
      width: 61,
      [theme.breakpoints.down('xs')]: {
        width: 45,
      },
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    box: {
      background: '#F1F1F1',
      textAlign: 'center',
      paddingBottom: 5,
      borderRadius: 3,
      marginLeft: 2,
      marginRight: 2,
      minWidth: 45,
      [theme.breakpoints.up('md')]: {
        marginLeft: 10,
        marginRight: 10,
      },
      [theme.breakpoints.up('sm')]: {
        marginLeft: 5,
        marginRight: 5,
      },
    },
    text: {
      [theme.breakpoints.up('md')]: {
        fontSize: 12,
      },
      fontSize: 10,
      fontWeight: 'bold',
    },
  }),
);

export const useStylesPremium = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      alignItems: 'center',
    },
    modalpaper: {
      backgroundColor: '#112131',
      outline: 0,
      overflowY: 'hidden',
      border: '3px solid #EBE4C8',
      boxShadow: theme.shadows[5],
      position: 'relative',
      width: '90%',
      height: '95%',
      [theme.breakpoints.up('md')]: {
        width: 950,
        border: '9px solid #EBE4C8',
      },
    },
    modalReviewContainer: {
      borderTop: '1px solid #DDDDDD',
      width: '100%',
      height: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingTop: theme.spacing(2),
      overflowY: 'auto',
      position: 'relative',
    },
    bgImg: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      width: '100%',
      height: 'auto',
      zIndex: 0,
    },
    modalcloseicon: {
      position: 'absolute',
      right: 7,
      top: 8,
      color: '#EBE4C8',
    },
    slidebox: {
      zIndex: 99,
    },
    slidediv: {
      outline: 0,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      alignItems: 'center',
      textAlign: 'center',
      position: 'relative',
      height: 200,
      [theme.breakpoints.up('sm')]: {
        height: 300,
      },
      [theme.breakpoints.up('md')]: {
        height: 400,
      },
    },
    modalimg: {
      zIndex: 0,
      width: 'auto',
      maxWidth: '80%',
      height: 'auto',
      maxHeight: '95%',
      margin: 'auto',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      filter: 'drop-shadow(0px 0px 15px #fae68f60)',
    },
    titlebox: {
      minHeight: 65,
      position: 'relative',
    },
    title: {
      paddingTop: 17,
      paddingBottom: 11,
      textAlign: 'center',
      margin: 'auto',
      fontSize: 18,
      width: '70%',
      color: '#EBE4C8',
      height: '100%',
      [theme.breakpoints.up('sm')]: {
        paddingTop: 13,
        fontSize: 24,
        width: '80%',
      },
    },
    userid: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
      fontSize: 16,
      color: '#EBE4C8',
    },
    contentbox: {
      zIndex: 98,
    },
    content: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      textAlign: 'center',
      fontSize: 20,
      whiteSpace: 'pre-wrap',
      color: '#EBE4C8',
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.down('xs')]: {
        fontSize: 15,
      },
    },
    selfcoursebox: {
      position: 'relative',
      width: '100%',
      zIndex: 0,
      height: 300,
      marginBottom: theme.spacing(6),
      [theme.breakpoints.down('md')]: {
        height: 270,
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(10),
        height: 200,
      },
      [theme.breakpoints.down('xs')]: {
        height: 150,
        marginBottom: 100,
      },
    },
    educoursebox: {
      position: 'relative',
      width: '100%',
      zIndex: 0,
      height: 500,
      [theme.breakpoints.down('md')]: {
        height: 400,
      },
      [theme.breakpoints.down('sm')]: {
        height: 300,
      },
      [theme.breakpoints.down('xs')]: {
        height: 250,
        marginBottom: theme.spacing(8),
      },
    },
    course: {
      zIndex: 99,
      color: '#EBE4C8',
      fontSize: 17,
      position: 'absolute',
      right: 15,
      top: 0,
      marginLeft: 15,
      [theme.breakpoints.down('xs')]: {
        fontSize: 15,
      },
    },
  }),
);
