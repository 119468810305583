import React, { useState } from 'react';
import styled from 'styled-components';
import {
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Payment from '@material-ui/icons/Payment';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Link } from 'react-router-dom';
import { useStylesNavbar } from '../../../../hooks/admin/navbar/useStyle';
import { useQuery } from 'react-apollo';
import LATEST_ORDER_TIME from '../gql';
import useGetCPId from '../../../../hooks/admin/common/useGetCPId';

const RouterLink = styled(Link)`
  text-decoration: none;
  color: black;
`;

const OrderNavbar = () => {
  const classes = useStylesNavbar();

  const [open, setOpen] = useState(false);
  const [LatestOrderDate, setLastOrderDate] = useState(-1);
  const cpId = useGetCPId();

  const handleContentClick = () => {
    setOpen(!open);
  };

  useQuery(LATEST_ORDER_TIME, {
    fetchPolicy: 'no-cache',
    onCompleted: (result) => {
      const parseDate = (parsedDate = '') => {
        if (parsedDate.length === 0) {
          return Math.floor(new Date().getTime() / 1000 / 60 / 60 / 24 + 0.375);
        }

        return Math.floor(
          new Date(parsedDate).getTime() / 1000 / 60 / 60 / 24 + 0.375,
        );
      };

      if (result.orders.items.length === 0) {
        setLastOrderDate(-1);
        return;
      }
      const passedDate =
        parseDate() - parseDate(result.orders.items[0]['createdAt']);

      setLastOrderDate(passedDate);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    <>
      <ListItem button onClick={handleContentClick}>
        <ListItemIcon>
          <Payment />
        </ListItemIcon>
        <ListItemText
          primary="주문 관리"
          secondary={
            <span style={{ color: '#FF9E36', fontWeight: 'bold' }}>
              {cpId == -1 && LatestOrderDate >= 0
                ? `최근 주문: ${
                    LatestOrderDate ? LatestOrderDate + '일 전' : '오늘'
                  }`
                : ''}
            </span>
          }
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <RouterLink to="/admin/order" className={classes.linkText}>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText
                primary="주문 목록"
                secondary={
                  <span style={{ color: '#FF9E36', fontWeight: 'bold' }}>
                    {cpId == -1 && LatestOrderDate >= 0
                      ? `최근 주문: ${
                          LatestOrderDate ? LatestOrderDate + '일 전' : '오늘'
                        }`
                      : ''}
                  </span>
                }
              />
            </ListItem>
          </RouterLink>
          <RouterLink to="/admin/delivery" className={classes.linkText}>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="교재 배송" />
            </ListItem>
          </RouterLink>
          <RouterLink to="/admin/CPMailManagePage" className={classes.linkText}>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="CP사 메일 관리" />
            </ListItem>
          </RouterLink>
        </List>
      </Collapse>

      <Divider />
    </>
  );
};

export default OrderNavbar;
