import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import { ExpansionPanel } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(7),
      },
    },
    tellnumber: {
      fontSize: 48,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: 40,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 30,
      },
    },
    tellnumber2: {
      fontSize: 25,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
      },
    },
    btnbox: {
      paddingTop: theme.spacing(4),
      textAlign: 'right',
    },
    helpbtn: {
      width: 190,
      height: 45,
      fontSize: 17,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        fontSize: 20,
        height: 60,
      },
    },
    subtext: {
      fontSize: 20,
      fontWeight: 'lighter',
      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
      },
    },
    link: {
      textDecoration: 'none',
    },
    arrowicon: {
      paddingTop: 1,
      [theme.breakpoints.down('xs')]: {
        paddingTop: 3,
        fontSize: 20,
      },
    },
    callicon: {
      fontSize: 17,
      marginRight: 5,
      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
      },
    },
  }),
);

export default useStyles;

export const useStylesBoardList = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: 'none',
      color: '#2B2B2B',
    },
    pagination: {
      marginTop: theme.spacing(2),
    },
    badgeCell: {
      width: 83,
    },
    badge: {
      fontSize: 15,
      width: 83,
      height: 25,
      paddingTop: 2,
      [theme.breakpoints.down('xs')]: {
        fontSize: 10,
        width: 51,
        height: 15,
        padding: 0,
        marginRight: theme.spacing(1),
        float: 'left',
      },
      fontWeight: 'bold',
      border: '1px solid #E6E6E6',
      textAlign: 'center',
    },
    eventBadge: {
      fontSize: 15,
      width: 83,
      height: 25,
      paddingTop: 2,
      [theme.breakpoints.down('xs')]: {
        fontSize: 10,
        width: 51,
        height: 15,
        padding: 0,
        marginRight: theme.spacing(1),
        float: 'left',
      },
      borderRadius: 4,
      textAlign: 'center',
      backgroundColor: '#ff9633',
      color: 'white',
    },
    eventEndBadge: {
      fontSize: 15,
      width: 83,
      height: 25,
      paddingTop: 2,
      [theme.breakpoints.down('xs')]: {
        fontSize: 10,
        width: 51,
        height: 15,
        padding: 0,
        marginRight: theme.spacing(1),
        float: 'left',
      },
      borderRadius: 4,
      textAlign: 'center',
      backgroundColor: '#5A5A5A',
      color: 'white',
    },
    title: {
      fontSize: 20,
      [theme.breakpoints.down('xs')]: {
        fontSize: 15,
        fontWeight: 'lighter',
        marginBottom: theme.spacing(1),
      },
    },
    date: {
      fontSize: 15,
      [theme.breakpoints.down('xs')]: {
        fontSize: 10,
        fontWeight: 'lighter',
      },
    },
  }),
);

export const useStylesIndexBoard = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(2),
    },
    link: {
      textDecoration: 'none',
      color: '#2B2B2B',
    },
    text: {
      fontSize: 15,
      fontWeight: 'lighter',
    },
    date: {
      textAlign: 'right',
    },
    row: {
      marginBottom: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      borderBottom: '1px solid #DDDDDD',
    },
  }),
);

export const useStylesCategory = makeStyles((theme: Theme) =>
  createStyles({
    categorycontainer: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(5),
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(7),
      },
    },
    idtext: {
      color: '#FF9633',
      fontWeight: 'bold',
      fontSize: 27,
      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
      },
      cursor: 'pointer',
    },
    notid: {
      color: '#3a3a3a',
      fontWeight: 'bold',
      fontSize: 27,
      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
      },
      cursor: 'pointer',
    },
    box: {
      display: 'inline-flex',
    },
    divider: {
      height: 15,
      margin: theme.spacing(2, 2, 0, 2),
      border: '1px solid #707070',
      opacity: 0.2,
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(1),
      },
    },
    searchBar: {
      marginTop: theme.spacing(2),
    },
  }),
);

export const useStylesFAQ = makeStyles((theme: Theme) =>
  createStyles({
    question: {
      fontSize: 20,
      fontWeight: 'lighter',
      [theme.breakpoints.down('xs')]: {
        fontSize: 15,
      },
    },
    answercontainer: {
      borderTop: '1px solid #DDDDDD',
    },
    answer: {
      fontSize: 20,
      fontWeight: 'lighter',
      [theme.breakpoints.down('xs')]: {
        fontSize: 15,
      },
    },
    answerA: {
      marginTop: 20,
      fontSize: 20,
      fontWeight: 'lighter',
      [theme.breakpoints.down('xs')]: {
        fontSize: 15,
        marginTop: 15,
      },
    },
  }),
);

export const useStylesForm = makeStyles((theme: Theme) =>
  createStyles({
    btndiv: {
      textAlign: 'center',
    },
    label: {
      fontSize: 22,
      fontWeight: 'bold',
      marginBottom: theme.spacing(1),
    },
    bluelabel: {
      fontSize: 16,
      color: '#335AE8',
      marginBottom: theme.spacing(1),
    },
    submitbtn: {
      width: '100%',
      height: 67,
      fontSize: 25,
      [theme.breakpoints.down('xs')]: {
        position: 'fixed',
        zIndex: 1,
        left: 0,
        bottom: 0,
        right: 0,
      },
      [theme.breakpoints.up('sm')]: {
        maxWidth: 451,
      },
    },
    guidelineWrapper: {
      border: '1px solid #C0BFBF',
      padding: '10px',
      borderRadius: '4px',
      width: '100%',
    },
    guidelineText: {
      fontWeight: 'lighter',
    },
    ordertitle: {
      paddingTop: theme.spacing(1),
    },
    labelicon: {
      paddingTop: 3,
      fontSize: 18,
    },
  }),
);

export const useStylesInquiry = makeStyles((theme: Theme) =>
  createStyles({
    replyCell: {
      width: 100,
      [theme.breakpoints.up('sm')]: {
        width: 150,
      },
    },
    replyColor: {
      border: '1px solid #E6E6E6',
      textAlign: 'center',
      color: '#FF9633',
      fontWeight: 'bold',
      fontSize: 15,
      [theme.breakpoints.up('sm')]: {
        fontSize: 20,
      },
    },
    notreply: {
      border: '1px solid #E6E6E6',
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: 15,
      [theme.breakpoints.up('sm')]: {
        fontSize: 20,
      },
    },
    notreplytext: {
      opacity: 0.5,
    },
    link: {
      cursor: 'pointer',
    },
    pagination: {
      marginTop: theme.spacing(2),
    },
    rowtitle: {
      fontWeight: 'lighter',
      fontSize: 15,
      [theme.breakpoints.up('sm')]: {
        fontSize: 20,
      },
    },
    rowdate: {
      fontWeight: 'lighter',
      fontSize: 13,
      [theme.breakpoints.up('sm')]: {
        fontSize: 15,
      },
    },
    created: {
      fontSize: 15,
    },
  }),
);

export const useStylesView = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(3),
    },
    title: {
      fontSize: 20,
      fontWeight: 'lighter',
      [theme.breakpoints.up('sm')]: {
        fontSize: 30,
      },
    },
    gubundiv: {
      fontSize: 13,
      [theme.breakpoints.up('sm')]: {
        fontSize: 20,
      },
    },
    gubun: {
      fontSize: 10,
      [theme.breakpoints.up('sm')]: {
        fontSize: 15,
      },
      opacity: 0.5,
    },
    link: {
      textDecoration: 'none',
    },
    body: {
      minHeight: 100,
      fontSize: 15,
      fontWeight: 'lighter',
      [theme.breakpoints.up('sm')]: {
        fontSize: 20,
      },
      whiteSpace: 'pre-wrap',
    },
    editbtn: {
      marginRight: theme.spacing(1),
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalpaper: {
      backgroundColor: theme.palette.background.paper,
      border: '0px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        width: 500,
      },
    },
    modalcontent: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      fontSize: 20,
      fontWeight: 'bolder',
    },
  }),
);

export const CustomExpansionPanel = withStyles({
  root: {
    border: '1px solid #E6E6E6',
  },
})(ExpansionPanel);

export const useStylesSearch = makeStyles(() =>
  createStyles({
    formcontrol: {
      width: '100%',
    },
    select: {
      width: '100%',
      height: 40,
    },
    input: {
      width: '100%',
      height: 40,
    },
    btn: {
      width: '100%',
      height: 40,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  }),
);
