//로그아웃 요청
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { logout } from '../../modules/auth';
import { History } from 'history';

export default function useLogout(history: History) {
  const dispatch = useDispatch();

  return useCallback(
    (type = null) => {
      dispatch(logout());
      if (type === 'admin') history.replace('/');
      else history.push('/login');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );
}
