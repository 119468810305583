import gql from 'graphql-tag';

const GET_USER = gql`
  query user {
    user {
      id
      accountId
      role {
        id
        name
        isAdmin
        useSpecificPermission
        apiPermissions {
          id
          name
          apiType
          allowRead
          allowWrite
          allowDelete
        }
        pagePermissions {
          id
          name
          url
        }
      }
    }
  }
`;

export const GET_USER_CPID = gql`
  query userCpId {
    user {
      accountId
    }
    contentProvider {
      id
      user {
        accountId
      }
    }
  }
`;

export default GET_USER;
