import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import useStatus from '../../../hooks/index/useStatus';
import { Skeleton } from '@material-ui/lab';
import { useStylesCenterbanner } from '../../../hooks/index/useStyles';

const CenterBanner = () => {
  const classes = useStylesCenterbanner();
  const { mainAds } = useStatus();

  const CourseSkeleton = (
    <div className={classes.skeletoncard}>
      <Skeleton variant="rect" width="100%" height="100%" animation={false} />
    </div>
  );

  return (
    <>
      <Grid item xs={12} sm={6}>
        {mainAds?.length > 0 ? (
          <Link to={mainAds[0].url}>
            <img
              src={mainAds[0].image.url}
              width="100%"
              height="auto"
              alt="메인배너"
            />
          </Link>
        ) : (
          CourseSkeleton
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        {mainAds?.length > 1 ? (
          <Link to={mainAds[1].url}>
            <img
              src={mainAds[1].image.url}
              width="100%"
              height="auto"
              alt="메인배너"
            />
          </Link>
        ) : (
          CourseSkeleton
        )}
      </Grid>
    </>
  );
};

export default CenterBanner;
