import { useState, useCallback } from 'react';

import { useLazyQuery } from '@apollo/react-hooks';
import GET_USER from '../admin/useUser';

import useAdminAccess from '../../auth/useAdminAccess';

export default function useSetUserPermission() {
  const adminAccess = useAdminAccess();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [getAdminPermission] = useLazyQuery(GET_USER, {
    fetchPolicy: 'no-cache',
    onCompleted: (result) => {
      if (result && result.user !== undefined) {
        const {
          isAdmin,
          name,
          apiPermissions,
          pagePermissions,
          useSpecificPermission,
        } = result.user.role;
        const accountId = result.user.accountId;

        adminAccess({
          accountId,
          isAdmin,
          name,
          apiPermissions,
          pagePermissions: pagePermissions.map((item: any) => item.url),
          useSpecificPermission,
        });
      } else {
        setError('Access denied');
      }
      setLoading(false);
    },
    onError: (error) => {
      setError(error.message);
      setLoading(false);
    },
  });

  const onPermissionRequest = useCallback(() => {
    setLoading(true);
    getAdminPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onPermissionRequest,
    loading,
    error,
  };
}
