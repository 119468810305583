import { useState, useEffect } from 'react';
import useStatus from '../../../hooks/auth/useStatus';

export default function useRestrictPermission(path: string) {
  const { permission, isAdmin } = useStatus();

  const [pass, setPass] = useState<boolean>(false);
  const [onlyRootAdmin, setOnlyRootAdmin] = useState<boolean>(false);

  useEffect(() => {
    if (permission.name === null || !isAdmin) {
      // 관리자 권한이 없으면 접속 불가
      setPass(false);
    } else {
      if (permission.useSpecificPermission === false) {
        //최고관리자
        setPass(true);
        setOnlyRootAdmin(true);
      } else {
        const result = permission.pagePermissions.filter((item) =>
          path.includes(item),
        );
        setPass(result.length > 0);
        setOnlyRootAdmin(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, isAdmin, permission]);

  return {
    pass,
    onlyRootAdmin,
  };
}
