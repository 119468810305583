import { createReducer } from 'typesafe-actions';
import { CartState, BookList } from './interface';
import { CartAction } from './types';
import {
  GET_CART,
  ADD_CHECK_ITEM,
  REMOVE_CHECK_ITEM,
  ADD_ITEM_BOOK,
  REMOVE_ITEM_BOOK,
  REMOVE_CHECKLIST,
} from './actions';
import { Cart, Book, Course } from '../../components/mypage/interface';

const initialState: CartState = {
  cartList: [],
  checkList: [],
  bookList: [],
};

const cart = createReducer<CartState, CartAction>(initialState, {
  [GET_CART]: (state, { payload: list }) => {
    return {
      cartList: list,
      checkList: [...list.map((item) => item.id)],
      bookList: list.map((item: Cart) => {
        const bookIdList: number[] = [];
        const cartItemBooks: Book[] = [];
        item.product.courses.forEach((course: Course) =>
          course.relatedBooks.forEach((book: Book) => {
            if (!bookIdList.includes(book.id)) {
              bookIdList.push(book.id);
              cartItemBooks.push(book);
            }
          }),
        );
        return {
          cartItemId: item.id,
          books: cartItemBooks,
        };
      }),
    };
  },
  [ADD_CHECK_ITEM]: (state, { payload: cartItemId }) => {
    if (state.checkList.includes(cartItemId)) {
      return state;
    }
    return {
      ...state,
      checkList: state.checkList.concat(cartItemId),
    };
  },
  [REMOVE_CHECK_ITEM]: (state, { payload: cartItemId }) => {
    return {
      ...state,
      checkList: state.checkList.filter((id: number) => id !== cartItemId),
    };
  },
  [ADD_ITEM_BOOK]: (state, { payload }) => {
    return {
      ...state,
      cartList: state.cartList.map((item: Cart) => {
        if (item.id === payload.id) {
          return {
            ...item,
            price: item.price + payload.book.price,
            books: item.books.concat(payload.book),
          };
        }
        return item;
      }),
    };
  },
  [REMOVE_ITEM_BOOK]: (state, { payload }) => {
    return {
      ...state,
      cartList: state.cartList.map((item: Cart) => {
        if (item.id === payload.id) {
          return {
            ...item,
            price: item.price - payload.book.price,
            books: item.books.filter(
              (book: Book) => book.id !== payload.book.id,
            ),
          };
        }
        return item;
      }),
    };
  },
  [REMOVE_CHECKLIST]: (state) => {
    return {
      ...state,
      cartList: state.cartList.filter(
        (item: Cart) => !state.checkList.includes(item.id),
      ),
      checkList: [],
      bookList: state.bookList.filter(
        (item: BookList) => !state.checkList.includes(item.cartItemId),
      ),
    };
  },
});

export default cart;
