import React from 'react';
import { Paper, Typography, Grid, Hidden } from '@material-ui/core';
import { LectureReview } from '../../review/interface';
import { Link } from 'react-router-dom';
import { useStylesReviewCard } from '../../../hooks/index/useStyles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import StarIconCard from '../../review/StarIconCard';
interface ReviewSliderItemProps {
  item: LectureReview;
}

const ReviewSliderItem = ({ item }: ReviewSliderItemProps) => {
  const classes = useStylesReviewCard();

  return (
    <Paper variant="outlined" square className={classes.paper}>
      <Link to={`/review`} className={classes.link}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg={7}>
                <Typography className={classes.title} noWrap>
                  {item.learningCourse.course.name}
                </Typography>
                <Typography className={classes.userId}>
                  ID : {item.user.accountId.substring(0, 2)}****
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={5}>
                <StarIconCard review={item} type="index" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.content}>
              {item.whatHaveILearned} {item.overallThoughts}{' '}
              {item.thoughtsOnTeacher}
            </Typography>
            <Typography className={classes.lecture}>
              {item.lecture.name} 수업에 대한 후기입니다.
            </Typography>
          </Grid>
          <Hidden smUp>
            <Grid item xs={12} className={classes.morelink}>
              <Typography className={classes.moretext}>
                더보기 <ArrowForwardIosIcon className={classes.moreicon} />
              </Typography>
            </Grid>
          </Hidden>
        </Grid>
      </Link>
    </Paper>
  );
};

export default ReviewSliderItem;
